
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../../store/store'
import { UploadScopesReportFilterType, updateUploadScopesReportFilterType } from '../../../../store/slices/filters'

import '../styles.css'

export const FilterScopes = () => {
  const categoriesFilter: UploadScopesReportFilterType[] = ["scope1", "scope2"];
  const dispatch = useDispatch();

  const {filterUploadScopesReport} = useSelector((state: RootState) => state.filters);

  const handleFilterLeaderboardCategory = (value: UploadScopesReportFilterType) => {
    if (value === filterUploadScopesReport) dispatch(updateUploadScopesReportFilterType("scope1"))
    else dispatch(updateUploadScopesReportFilterType(value));
  }

  const getBorderRadius = (index: number) => {
    if (index === 0) return '8px 0 0 8px'
    else if (index === categoriesFilter.length - 1) return '0 8px 8px 0'
    else return '0 0 0 0'
  }

  const getLabel = (value: UploadScopesReportFilterType) => {
    if (value === 'scope1') return 'SCOPE 1'
    else return 'SCOPE 2'
  }

  return (
    <div className='d-flex mx-0 px-0 mb-4'>
    {
      categoriesFilter.map((value: UploadScopesReportFilterType, index: number) => (
      <div key={value} className='justify-content-end'>
        <button
          type='button'
          // disabled={!loading}
          className={`${filterUploadScopesReport === value ? 'upload-data-category-filter-button' : 'upload-data-category-filter-outline-button'} m-0 px-16`}
          style={{
            borderRadius: getBorderRadius(index),
            fontWeight: '700',
            backgroundColor: filterUploadScopesReport === value ? '#191C30' : '#FFFFFF',
            border: filterUploadScopesReport === value ? '1px solid #191C30' : '1px solid #CDCDCD',
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleFilterLeaderboardCategory(value);
          }}
        >
          {getLabel(value)}
          <p className='m-0 fs-7' style={{fontWeight: 600}}>
            {value === "scope1" ? 'Direct Emissions' : 'Indirect Emissions'}
          </p>
        </button>
      </div>
      ))
    }
    </div>
  )
}
