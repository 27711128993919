import { count } from "console"

export const contractsData = [
  {
    name: 'Carbon Credits Agreement',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Contracts/Carbon%20Credits%20Agreement.pdf',
    date: ''
  },
  {
    name: 'Farmer Donor Agreement',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Contracts/Farmer%20Donor%20Agreement.pdf',
    date: ''
  },
  {
    name: 'Donor Implementation Partner Agreement',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Contracts/Donor%20Implementation%20Partner%C2%A0Agreement.pdf',
    date: ''
  },
]

export const farmerDetailsData = [
  {
    name: 'List of Farmer',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Farmer_Details/List%20of%20Farmer.xlsx',
    date: ''
  },
  {
    name: `Farmer's Data`,
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Farmer_Details/Farmers_Data.pdf',
    date: ''
  },
]

export const donationsData = [
  {
    name: 'Form10 BE_AAATN2405L05241000104',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q1.pdf',
    date: ''
  },
  // {
  //   name: 'Form10 BE_AAATN2405L05241000104 - Q2',
  //   url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q2.pdf',
  //   date: ''
  // },
  // {
  //   name: 'Form10 BE_AAATN2405L05241000104 - Q3',
  //   url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q3.pdf',
  //   date: ''
  // },
  // {
  //   name: 'Form10 BE_AAATN2405L05241000104 - Q4',
  //   url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q4.pdf',
  //   date: ''
  // }
]

export const receiptsData = [
  {
    name: 'Receipt1',
    url: 'https://www.google.com',
    date: '03-03-2023'
  },
  {
    name: 'Receipt2',
    url: 'https://www.google.com',
    date: '04-04-2023'
  },
  {
    name: 'Receipt3',
    url: 'https://www.google.com',
    date: '05-05-2023'
  },
  {
    name: 'Receipt4',
    url: 'https://www.google.com',
    date: '06-06-2023'
  },
  {
    name: 'Receipt5',
    url: 'https://www.google.com',
    date: '07-07-2023'
  },
  {
    name: 'Receipt6',
    url: 'https://www.google.com',
    date: '08-08-2023'
  },
]

export const forestPlantationChartData = {
  series: [
    {name: 'Acres Purchased', data: [10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8]},
    {name: 'Acres Planted', data: [3.9, 4.2, 4.6, 5.5, 5.9, 6.2, 6.2, 7.35, 9.25, 9.75, 10.8]}
  ],
  categories: ['Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024', 'Jul 2024', 'Aug 2024', 'Sep 2024', 'Oct 2024', 'Nov 2024']
}

export const forestDonutContributionChartData = [
  {name: 'MALES', count: 110},
  {name: 'FEMALES', count: 10}
]

export const forestTotalForestAreaChartData = [
  {name: 'AREA', count: 120}
]

export const forestTotalTreesPlantedChartData = [
  {name: 'TREES PLANTED', count: 1000000}
]

export const forestTotalRecapturedChartData = [
  {name: 'RECAPTURED', count: 1000000}
]