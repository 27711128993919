/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { FilterScopes } from './components/filter-scopes'
import { RootState } from '../../../store/store'
import { useAppDispatch } from '../../../store/store'
import { UploadDataScopeOneSubcategoriesEnum, UploadDataScopeTwoSubcategoriesEnum } from '../../../store/slices/filters'
import { FilterSubcategories } from './components/filter-subcategories'
import AccordionUploadData from './components/accordion-upload-s1-m1'
import { docsData } from './docs-data'
import { LockWrapperPartial } from '../../../../_metronic/partials/components/lock-wrapper/LockWrapper'

const UploadPage = () => {
  const dispatch = useAppDispatch()
  const {lockedScreens} = useSelector((state: RootState) => state.profile)
  const {filterUploadScopesReport, filterUploadDataScopeOneSubcategories, filterUploadDataScopeTwoSubcategories} = useSelector((state: RootState) => state.filters)

  const [currentAccordionsList, setCurrentAccordionsList] = useState<any>([])

  useEffect(() => {
    if (filterUploadScopesReport === 'scope1') {
      if (filterUploadDataScopeOneSubcategories === UploadDataScopeOneSubcategoriesEnum.MOBILE_CONSUMPTION) {
        setCurrentAccordionsList(docsData.scopeOne.mobileConsumption)
      } else if(filterUploadDataScopeOneSubcategories === UploadDataScopeOneSubcategoriesEnum.FUGITIVE_EMISSIONS) {
        setCurrentAccordionsList(docsData.scopeOne.FugitiveVehicles)
      } else if(filterUploadDataScopeOneSubcategories === UploadDataScopeOneSubcategoriesEnum.STATIONARY_COMBUSTION) {
        setCurrentAccordionsList(docsData.scopeOne.StaionaryCombustion)
      } else {
        setCurrentAccordionsList(docsData.scopeOne.Other)
      }
    } else {
      if (filterUploadDataScopeTwoSubcategories === UploadDataScopeTwoSubcategoriesEnum.LOCATION_BASED_METHOD) {
        setCurrentAccordionsList(docsData.scopeTwo.locationBasedMethod)
      } else if(filterUploadDataScopeTwoSubcategories === UploadDataScopeTwoSubcategoriesEnum.MARKET_BASED_METHOD) {
        setCurrentAccordionsList(docsData.scopeTwo.marketBasedMethod)
      } else {
        setCurrentAccordionsList(docsData.scopeTwo.Other)
      }
    }
  }, [filterUploadScopesReport, filterUploadDataScopeOneSubcategories, filterUploadDataScopeTwoSubcategories])

  return (
    <LockWrapperPartial isLocked={lockedScreens.uploadDataPage} description='This page allows you to upload data for various categories of Scope 1 and 2 emissions. After uploading, you can view the data on the emissions page. '>
      <div className='blank-page h-100 pb-12' style={{backgroundColor: '#F8F8F8'}}>
        {/* Scope Toggle */}
        <div className='pt-6 pb-1 px-6 d-flex justify-content-between' style={{backgroundColor: '#FFFFFF'}}>
          <FilterScopes />

          <div className='d-flex align-items-start gap-2'>
            <p className='d-flex m-0 fs-6 py-1 px-6' style={{fontWeight: 800, backgroundColor: '#FAF6FF', borderRadius: 40, border: '1px solid #D7D7D7'}}>
              Industry - Professional Services
            </p>
          </div>
        </div>

        {/* Subcategory Toggle */}
        <div className='pt-1 pb-6' style={{backgroundColor: '#FFFFFF', paddingLeft: '10px', paddingRight: '32px'}}>
          <FilterSubcategories />
        </div>

        {/* Upload Card */}
        <div className='px-6 mt-4'>
          {
            currentAccordionsList.map((item: any, index: number) => (
              <AccordionUploadData 
                key={`${item.title}`}
                index={index}
                scopeUploadData={item}
              />
            ))
          }
        </div>
      </div>
    </LockWrapperPartial>
  )
}

export default UploadPage