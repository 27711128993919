/* eslint-disable arrow-body-style */
/* eslint-disable dot-notation */

import { LockedScreensType } from "./types"

export const getLockedScreens = (lockedScreens: any): LockedScreensType => ({
  dashboardScopePage: typeof lockedScreens?.dashboard === 'number' && lockedScreens?.dashboard === 0,
  projectedEmissionsPage: typeof lockedScreens?.['dashboard-projected-emissions'] === 'number' && lockedScreens?.['dashboard-projected-emissions'] === 0,
  scopeOnePage: typeof lockedScreens?.['dashboard-scope-one'] === 'number' && lockedScreens?.['dashboard-scope-one'] === 0,
  scopeTwoPage: typeof lockedScreens?.['dashboard-scope-two'] === 'number' && lockedScreens?.['dashboard-scope-two'] === 0,
  scopeThreePage: typeof lockedScreens?.['dashboard-scope-three'] === 'number' && lockedScreens?.['dashboard-scope-three'] === 0,
  forestPage: typeof lockedScreens?.forest === 'number' && lockedScreens?.forest === 0,
  uploadDataPage: typeof lockedScreens?.['upload-data'] === 'number' && lockedScreens?.['upload-data'] === 0,
  surveyQuestionsPage: typeof lockedScreens?.['survey-questions'] === 'number' && lockedScreens?.['survey-questions'] === 0,
  forestDocsPage: typeof lockedScreens?.['forest-docs'] === 'number' && lockedScreens?.['forest-docs'] === 0,
  dashboardEmissionPage: typeof lockedScreens?.['dashboard-emission'] === 'number' && lockedScreens?.['dashboard-emission'] === 0,
  detailsPage: typeof lockedScreens?.['details'] === 'number' && lockedScreens?.['details'] === 0,
  leaderboardPage: typeof lockedScreens?.['leaderboard'] === 'number' && lockedScreens?.['leaderboard'] === 0,
})
