export const docsData = {
  scopeOne: {
    mobileConsumption: [
      {title: "Company Owned Vehicles", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/125xXlFPsxFR3caC3keZTwow0RVVsLNVlM6VFulWWICw/edit?usp=drive_link"},
      {title: "Third Party Transportation Under Company Control", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1Nzg800fXGUKeQn7z76sX8VF7o_jk7W6o_INdMA19CAE/edit?usp=drive_link"},
      {title: "Leased Vehicles Operated by the Company", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1mc4psE_wz-G87tL1QCkFOsiiNeLgfqVBGJf7XTjpS6I/edit?usp=drive_link"},
    ],
    FugitiveVehicles: [
      {title: "Refrigerant Leakage from Cooling Systems", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1ij6itvgZUSTR7_e5Xuc9-1dBV736NhtEs6PnW_NOFlQ/edit?usp=drive_link"},
      {title: "Fire Extinguishers", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1R9Iva0MMKypKp7xb7pCpwE4UG3gIeyc4YAI5dzZZ0f8/edit?usp=drive_link"},
    ],
    StaionaryCombustion: [
      {title: "Heating Systems", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1M30UoR9GAOMLJBKJuveWOdlnhJ8pK88Ha8HwHSt2JeY/edit?usp=drive_link"},
      {title: "Generators", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1Iqb0Krppb7-i-uGOmPn-XmikJMAxgakfJ7o8cAKBbVE/edit?usp=drive_link"},
      {title: "Boilers and Furnaces", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1Z4mHEE336W_xuhOZ9j4V-JcLZTzTX45w8RNe3f4EdjI/edit?usp=drive_link"},
    ], 
    Other: [{ title: "Other", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/10BkFa6ojCmMcUDWzBBCYRRpoPUbpTsqey5SEsZi0D-0/edit?gid=1355393743#gid=1355393743"}]
  },
  scopeTwo: {
    marketBasedMethod: [
      {title: "Power Purchased Agreement", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/16-xvIturgFtwGonEvi2XcQFTbwd5Yg9AKgwCtVuKWK0/edit?usp=drive_link"},
    ],
    locationBasedMethod: [
      {title: "Natural Gas from Location Grid", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1PSi6TG3HUi57hCW9v_nak3yAALiquEmEnNhC1qeew-w/edit?usp=drive_link"},
      {title: "Electricity from Location Grid", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1Iv-wTMQ1fhuOhc5WBIlRwexxTOlLsvwsCcl-fgwtMIk/edit?usp=drive_link"},
    ],
    Other: [
      {title: "Other ", status: "not-uploaded", link: "https://docs.google.com/spreadsheets/d/1o7gnnhw8s_r9ESWebvmpip8ZMCkBxqCxxeB6P24WRE8/edit?gid=1355393743#gid=1355393743"}
    ]
  }
}