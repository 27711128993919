import {
  ProfileData,
  ProfileDataResponseType,
  EmployeeDataResponseType,
} from '../../../_metronic/layout/types'
import api from '../../services/api'
import {APITags, MethodType} from '../../services/constants'
import API_ENDPOINTS from './apiPaths'

const profileApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfileDetails: builder.query<ProfileData, void>({
      query: () => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.ME}`,
        method: MethodType.get,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
      }),
      providesTags: [APITags.GET_PROFILE],
      transformResponse: (response: ProfileDataResponseType) => response?.data,
    }),
    updateAdmin: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.ADMIN_UPDATE}`,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
        data: {
          ...params,
        },
        method: MethodType.put,
      }),
      transformResponse: (response: EmployeeDataResponseType) => response?.data,
    }),
    uploadScopeData: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.UPLOAD_SCOPE_DATA}`,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
        data: params,
        method: MethodType.post,
        formData: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
      transformResponse: (response: any) => response?.data,
    }),
    uploadEmployees: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.UPLOAD_EMPLOYEES}`,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
        data: params,
        method: MethodType.post,
        formData: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        } 
      }),
      transformResponse: (response: any) => response?.data,
    }),
    addEmployee: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.EMPLOYEE_CREATE}`,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
        data: {
          employees: [
            {
              name: params.name,
              email: params.email,
              department: params.department,
              location: params.location,
              division: params.division,
            },
          ],
        },
        method: MethodType.post,
      }),
      transformResponse: (response: EmployeeDataResponseType) => response?.data,
    }),
  }),
})

export const {
  useUpdateAdminMutation,
  useGetProfileDetailsQuery,
  useAddEmployeeMutation,
  useLazyGetProfileDetailsQuery,
  useUploadEmployeesMutation,
  useUploadScopeDataMutation
} = profileApis

