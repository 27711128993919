/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useSelector} from 'react-redux'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import '../styles.css'
import {DateRangeType} from '../types'
import {
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery,
  useLazyGetEmissionTrendsQuery,
} from '../../../../app/modules/dashboard/store/api'
import {getAllDatesOfCurrentMonth, getYYYYDDMMFormat} from '../../../../app/utils/dateFormatter'

import {RootState} from '../../../../app/store/store'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'
import { DownloadIcon } from '../../../assets/icons'
import ModalDownloadChart, { ChartType } from '../modal-download-charts/modal-download-chart'

export type ChartValueTypes = {
  disableAnimation?: boolean
  locationType: string
}

const CHART_LABEL_COLORS = ['#685097', '#A687CA'];

const getCopyChartOptions = (chartOptions: ApexOptions, copychartref: any) => {
  const copyChartOptions: ApexOptions = {...chartOptions};

  // if (copyChartOptions?.legend) copyChartOptions.legend.show = false;
  // if (copyChartOptions?.chart?.height) copyChartOptions.chart.height = '250px';

  const copychart = new ApexCharts(copychartref.current, copyChartOptions);
  return copychart;
}

function getChartOptions(
  _height: number, 
  categoryVal: string, 
  monthName: string, 
  locationType: string, 
  series2Data: number[],
  avgEployeesFlag: boolean, 
  totalActivitiesSeriesData: any[], 
  subActivitiesSeriesData: any[]
  ): ApexOptions {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const ht = _height

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
      markers: {
        width: 15,
        height: 15,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: CHART_LABEL_COLORS,
        offsetX: 0,
        offsetY: 0,
        radius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      colors: CHART_LABEL_COLORS,
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: [0, 0],
      colors: CHART_LABEL_COLORS,
      dashArray: [0, 0],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      shared: true, 
      intersect: false,
      custom: (itm) => {
        let data1 = (itm?.series[0][itm?.dataPointIndex]) || 0
        if (data1<10 && data1!==0) {
          data1 = data1?.toFixed(1)
        } else {
          data1 = Math.round(data1)
        }
        // const data2 = categoryVal !== 'All' && Math.ceil(itm.series[1][itm.dataPointIndex] + data1)
        const series2DataAtIndex = series2Data[itm?.dataPointIndex]
        let data2:any = (categoryVal !== 'All') ? (series2DataAtIndex) : 0
        if (data2<10 && data2!==0) {
          data2 = data2?.toFixed(1)
        } else {
          data2 = Math.round(data2)
        }

        let titleLabel = 'All Divisions'
        if (locationType === 'department') {
          titleLabel = 'All Departments'
        } else if (locationType === 'location') {
          titleLabel = 'All Locations'
        }

        // Activities Data
        const totActivities = totalActivitiesSeriesData[itm.dataPointIndex] || []
        const subActivities = subActivitiesSeriesData[itm.dataPointIndex] || []

        let strTotActivities = ''
        if (!(typeof totActivities === 'string' || totActivities instanceof String)) {
          totActivities?.map((act: any, index: number) => {
            const name = act?.name || ''
            let emm = act?.emission || 0
            if (emm !== 0 && emm < 10) {
              emm = emm?.toFixed(1)
            } else {
              emm = Math.round(emm)
            }
            if (name.length !== 0) {
              strTotActivities = strTotActivities.concat(
                `<p 
                    style="
                      font-size: 13px; 
                      margin: 0px; 
                      margin-bottom: 2px;
                      margin-left: 20px;"
                  >
                    ${name}: ${commaSeparatedFormat(emm)}
                  </p>
                  `
                )
            }
            return null
          })
        }
        if (strTotActivities.length !== 0 && categoryVal !== 'All') {
          strTotActivities = strTotActivities.concat('<hr>')
        }
        let strSubActivities = ''
        if (!(typeof totActivities === 'string' || totActivities instanceof String)) {
          subActivities?.map((act: any, index: number) => {
            const name = act?.name || ''
            let emm = act?.emission || 0
            if (emm !== 0 && emm < 10) {
              emm = emm?.toFixed(1)
            } else {
              emm = Math.round(emm)
            }
            if (name.length !== 0) {
              strSubActivities = strSubActivities.concat(
                `<p 
                    style="
                      font-size: 13px; 
                      margin: 0px; 
                      margin-bottom: 2px;
                      margin-left: 20px;"
                  >
                    ${name}: ${commaSeparatedFormat(emm)}
                  </p>
                  `
                )
            }
            return null
          })
        }


        if (avgEployeesFlag && categoryVal==='All') {
          return `<div>
                    <div style="padding:10px 15px 5px 10px; background-color:  #d8d8d8">
                      <h5>Emissions on ${monthName} ${itm.dataPointIndex+1} (kgCO2)</h5>
                    </div>
                    <div style="padding:10px 15px 5px 10px">
                      <h6 className="no-bold"> 
                        Avg. Emission : <span>${commaSeparatedFormat(data1)}</span>
                      </h6>
                    </div>
                  </div>`
        }
        return `<div>
                  <div style="padding:10px 15px 5px 10px; background-color:  #d8d8d8">
                    <h5>Emissions on ${monthName} ${itm.dataPointIndex+1} (kgCO2)</h5>
                  </div>
                  <div style="padding:10px 15px 5px 10px">
                    ${categoryVal === 'All' ? 
                      `
                        <h6 className="no-bold"> 
                          Total Emission : <span>${commaSeparatedFormat(data1)}</span>
                        </h6>
                        ${strTotActivities}
                      ` : 
                      `
                        <h6 className="no-bold"> 
                          ${titleLabel} : <span>${commaSeparatedFormat(data2)}</span>
                        </h6>
                        ${strTotActivities}
                        <h6> ${categoryVal} Emission : <span>${commaSeparatedFormat(data1)}</span></h6>
                        ${strSubActivities}
                      `
                    }
                  </div>
                </div>`
      },
      style: {
        fontSize: '12px',
      },
      marker: {
        show: false,
      },
      onDatasetHover: {
        highlightDataSeries: false
      }, 
      followCursor: true,
    },
    colors: ['#FD5C5C', 'rgba(242, 136, 129,0.3)'],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const EmissionContributionChart: FC<ChartValueTypes> = (props) => {
  const {disableAnimation, locationType} = props
  const chartref = useRef<HTMLDivElement | null>(null)
  const copychartref = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const selectedMonth = useSelector((state: RootState) => state.filters.filterMonth)
  const [categories, setCategories] = useState<string []>([])

  const [getTotalEmission, {data: totalEmissionData}] = useLazyGetEmissionTrendsQuery()
  const [getEmissionTrend, {data: emissionTrend}] = useLazyGetEmissionTrendsQuery()

  const [getDivisionsList] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList] = useLazyGetDepartmentsListQuery()
  const [getLocationsList] = useLazyGetLocationsListQuery()
  // state
  const [downloadChartModalShow, setDownloadChartModalShow] = useState<boolean>(false);
  const [chartModalData, setChartModalData] = useState<any>({title: "Emission Contribution"});
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)
  const {filterDate, filterDivSubCategory, filterDeptSubCategory, filterLocSubCategory} = useSelector((state: RootState) => state.filters)
  const [listData, setListData] = useState(['All'])
  const [categoryVal, setCategoryVal] = useState<string>('All')
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()

  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryVal(e.target.value)
  }

  useEffect(() => {
    if (locationType === 'division') {
      setCategoryVal(filterDivSubCategory)
    }
    else if (locationType === 'department') {
      setCategoryVal(filterDeptSubCategory)
    }
    else if (locationType === 'location'){
      setCategoryVal(filterLocSubCategory)
    }
  }, [filterDivSubCategory, filterDeptSubCategory, filterLocSubCategory])

  const setInitialSelectedDate = (month: number) => {
    const currentDate = new Date()
    const selectedYear =
      month > currentDate.getUTCMonth() ? currentDate.getUTCFullYear() - 1 : currentDate.getUTCFullYear()
    let endDay
    if (month === currentDate.getUTCMonth()) {
      endDay = new Date(selectedYear, month, currentDate.getUTCDate())

      // let newComparedMonth = new Date(selectedYear,month+1,currentDate.getUTCDate())
      // setSelectedCompareToMonth(newComparedMonth)
    } else {
      endDay = new Date(selectedYear, month + 1, 0)
    }
    setSelectedDateRange({
      startDate: new Date(selectedYear, month, 1),
      endDate: endDay,
    })
  }
  const populateDataList = (getApi: any, setData: any) => {
    getApi({})
      .then((res: any) => {
        const tempData = ['All']
        res.data?.forEach((itm: any) => {
          tempData.push(itm.name)
        })
        setData(tempData)
      })
      .catch((err: any) => console.log(err))
  }

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    if (locationType === 'department') {
      populateDataList(getDepartmentsList, setListData)
    } else if (locationType === 'location') {
      populateDataList(getLocationsList, setListData)
    } else if (locationType === 'division') {
      populateDataList(getDivisionsList, setListData)
    }
  }, [])

  useEffect(() => {
    const month = selectedMonth

    const monthIndex = months.findIndex((itm) => itm.toLowerCase() === month.toLowerCase())

    setInitialSelectedDate(monthIndex)
  }, [selectedMonth])

  useEffect(() => {
    // if (selectedDateRange) {
    //   const reqObject1 = {
    //     fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //     toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //     category: locationType,
    //     by_average: avgEployeesFlag,
    //   }
    //   getTotalEmission(reqObject1)
    //   if (categoryVal === 'All') {
    //     const reqObject = {
    //       fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //       toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //       category: locationType,
    //       by_average: avgEployeesFlag,
    //     }
    //     getEmissionTrend(reqObject)
    //   } else {
    //     const reqObject = {
    //       fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //       toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //       category: locationType,
    //       subCategory: categoryVal,
    //       by_average: avgEployeesFlag,
    //     }
    //     getEmissionTrend(reqObject)
    //   }
    // }
    if (filterDate.startDate !== '') {
      const reqObject1 = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        category: locationType,
        by_average: avgEployeesFlag,
      }
      getTotalEmission(reqObject1)
      if (categoryVal === 'All') {
        const reqObject = {
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: locationType,
          by_average: avgEployeesFlag,
        }
        getEmissionTrend(reqObject)
      } else {
        const reqObject = {
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: locationType,
          subCategory: categoryVal,
          by_average: avgEployeesFlag,
        }
        getEmissionTrend(reqObject)
      }
    }
  }, [avgEployeesFlag, categoryVal, selectedDateRange, filterDate])

  useEffect(() => {
    if (filterDate.startDate !== '') {
      setCategories(getAllDatesOfCurrentMonth(filterDate.startDate))
    }
  }, [filterDate])

  const resizeArray = (array: any, newSize: any) => {
    const changeSize = newSize - array.length
    if (changeSize > 0) {
      return array.concat(Array(changeSize).fill(0))
    }
    return array.slice(0, newSize)
  }

  const totalEmissionSeriesData: number[] = new Array(31).fill(0)
  const totalEmissionSeriesCopyData: number[] = new Array(31).fill(0)
  const subCategoryEmissionSeriesData: number[] = new Array(31).fill(0)
  const totalActivitiesSeriesData: any[] = new Array(31).fill(null)
  const subActivitiesSeriesData: any[] = new Array(31).fill(null)

  emissionTrend?.forEach((data: any) => {
    const index = new Date(data.dates).getUTCDate() - 1
    subCategoryEmissionSeriesData[index] = data.emission
    subActivitiesSeriesData[index] = data.activities
  })
  totalEmissionData?.forEach((data: any) => {
    const index = new Date(data.dates).getUTCDate() - 1
    const totHeight = data.emission - (subCategoryEmissionSeriesData[index] || 0)
    totalEmissionSeriesData[index] = (totHeight < 0) ? 0 : totHeight
    totalEmissionSeriesCopyData[index] = data.emission
    totalActivitiesSeriesData[index] = data.activities
  })
  const series = [
    {
      name: categoryVal === 'All' ? `Total Emission` : `${categoryVal} Emission`,
      data: subCategoryEmissionSeriesData,
      type: 'bar',
    },
  ]
  if (categoryVal !== 'All') {
    series.push({
      name: `Total Emission`,
      data: totalEmissionSeriesData,
      type: 'bar',
    })
  }

  const refreshMode = useCallback(() => {
    if (!chartref.current) {
      return undefined
    }
    const height = parseInt(getCSS(chartref.current, 'height'), 10)
    const values = getChartOptions(height, categoryVal, selectedMonth, locationType, totalEmissionSeriesCopyData, avgEployeesFlag, totalActivitiesSeriesData, subActivitiesSeriesData)

    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${commaSeparatedFormat(parseFloat(value?.toFixed(1)))}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartref.current, options)
    // copy chartOptions for modal download preview
    const copychart = getCopyChartOptions(options, copychartref);
    if (chart) {
      chart.render()
      copychart.render()
    }
    return chart
  }, [series, categories, disableAnimation, downloadChartModalShow])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  // const handleSubCategorySelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedMonth = e.target.value
  //   const selectedCompareToMonth = monthOptions.findIndex((month) => month === selectedMonth)
  //   setProgress(selectedMonth)
  //   onSubCategorySelect(selectedCompareToMonth.toString())
  // }, [])

  return (
    <div className='card mb-5 mb-xl-8 pt-2 pb-2 bordered-grey'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-2'>
        <h3 className='card-title align-items-center flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Emission Contribution</span>
        </h3>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm w-100 form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={categoryVal}
            onChange={handleCategorySelect}
          >
            {listData.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <button 
            type='button' 
            className='remove-btn-styles download-report-btn p-1 mx-2'
            onClick={() => setDownloadChartModalShow(true)}
            // style={{display: 'none'}}
            >
            <DownloadIcon color='#5CADC9'/> 
          </button>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-4 pb-2'>
        {/* begin::Chart */}
        <div ref={chartref} id='kt_charts_widget_3_chart' />
        {/* end::Chart */}
        {/* <div className='tips-container py-4 px-6 ml-2 mt-4 mb-2'>
          <div>
            <span className='tips-text'>{`Here's what you can do`}</span>
          </div>
          <img src={NextIcon} alt='' />
        </div> */}
      </div>
      {/* end::Body */}
      <div ref={copychartref} style={{display: 'none'}} />

      <ModalDownloadChart 
        show={downloadChartModalShow} 
        onHide={() => setDownloadChartModalShow(false)}
        chartref={copychartref}
        chartType={ChartType.CONTRIBUTION_BAR}
        data={chartModalData}
        />
    </div>
  )
}

export {EmissionContributionChart}
