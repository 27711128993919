export const FilterDataType = (props: {filterDataType: string, setFilterDataType: (value: string) => void, categories: string[]}) => {
  const {filterDataType, setFilterDataType, categories} = props;

  const handleFilterDataType = (value: string) => {
    setFilterDataType(value);
  }

  const getBorderRadius = (index: number) => {
    if (index === 0) return '20px'
    else if (index === categories.length - 1) return '20px'
    else return '0 0 0 0'
  }

  const getLabel = (value: string) => {
    if (value === categories[0]) return categories[0]
    else return categories[1]
  }

  return (
    <div className='d-flex m-0 p-1' style={{borderRadius: '30px', backgroundColor: '#D1D1D1'}}>
    {
      categories.map((value: string, index: number) => (
      <div key={`${value}`} className='justify-content-end m-0' >
        <button
          type='button'
          // disabled={!loading}
          className={`${filterDataType === value ? 'custom-group-category-filter-button' : 'custom-group-category-filter-outline-button'} m-0 px-8`}
          style={{
            borderRadius: getBorderRadius(index),
            padding: '6px 10px',
            fontWeight: '700',
            border: 'none',
            backgroundColor: filterDataType === value ? '#0491BD' : '#D1D1D1',
            fontSize: '13px',
            boxShadow: 'none',
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          disabled={['Data Based'].includes(value)}
          onClick={() => {
            handleFilterDataType(value);
          }}
        >
          {getLabel(value)}
        </button>
      </div>
      ))
    }
    </div>
  )
}
