import { RenderStatusPill } from "./render-status-pill";

interface AccordionWrapperProps {
  index: number;
  children: React.ReactNode;
  status: 'completed' | 'in_progress' | 'not_uploaded';
  title: string;
}

const getIndexedAccordion = (index: number, children: React.ReactNode, status: 'completed' | 'in_progress' | 'not_uploaded', title: string) => {
  const allAccordions: { [key: string]: React.ReactElement } = {
    '0': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='oneSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-oneSection' 
              aria-expanded="false" 
              aria-controls='panel-oneSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-oneSection' className="accordion-collapse collapse" aria-labelledby='oneSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '1': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twoSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twoSection' 
              aria-expanded="false" 
              aria-controls='panel-twoSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-twoSection' className="accordion-collapse collapse" aria-labelledby='twoSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '2': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='threeSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-threeSection' 
              aria-expanded="false" 
              aria-controls='panel-threeSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-threeSection' className="accordion-collapse collapse" aria-labelledby='threeSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '3': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fourSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fourSection' 
              aria-expanded="false" 
              aria-controls='panel-fourSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-fourSection' className="accordion-collapse collapse" aria-labelledby='fourSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '4': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fiveSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fiveSection' 
              aria-expanded="false" 
              aria-controls='panel-fiveSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-fiveSection' className="accordion-collapse collapse" aria-labelledby='fiveSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '5': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='sixSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-sixSection' 
              aria-expanded="false" 
              aria-controls='panel-sixSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-sixSection' className="accordion-collapse collapse" aria-labelledby='sixSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '6': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='sevenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-sevenSection' 
              aria-expanded="false" 
              aria-controls='panel-sevenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-sevenSection' className="accordion-collapse collapse" aria-labelledby='sevenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '7': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='eightSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-eightSection' 
              aria-expanded="false" 
              aria-controls='panel-eightSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-eightSection' className="accordion-collapse collapse" aria-labelledby='eightSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '8': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='nineSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-nineSection' 
              aria-expanded="false" 
              aria-controls='panel-nineSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-nineSection' className="accordion-collapse collapse" aria-labelledby='nineSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '9': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='tenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-tenSection' 
              aria-expanded="false" 
              aria-controls='panel-tenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-tenSection' className="accordion-collapse collapse" aria-labelledby='tenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '10': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='elevenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-elevenSection' 
              aria-expanded="false" 
              aria-controls='panel-elevenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-elevenSection' className="accordion-collapse collapse" aria-labelledby='elevenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '11': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twelveSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twelveSection' 
              aria-expanded="false" 
              aria-controls='panel-twelveSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-twelveSection' className="accordion-collapse collapse" aria-labelledby='twelveSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '12': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirteenSection' 
              aria-expanded="false" 
              aria-controls='panel-thirteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-thirteenSection' className="accordion-collapse collapse" aria-labelledby='thirteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '13': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fourteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fourteenSection' 
              aria-expanded="false" 
              aria-controls='panel-fourteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-fourteenSection' className="accordion-collapse collapse" aria-labelledby='fourteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '14': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fifteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fifteenSection' 
              aria-expanded="false" 
              aria-controls='panel-fifteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-fifteenSection' className="accordion-collapse collapse" aria-labelledby='fifteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '15': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='sixteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-sixteenSection' 
              aria-expanded="false" 
              aria-controls='panel-sixteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-sixteenSection' className="accordion-collapse collapse" aria-labelledby='sixteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '16': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='seventeenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-seventeenSection' 
              aria-expanded="false" 
              aria-controls='panel-seventeenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-seventeenSection' className="accordion-collapse collapse" aria-labelledby='seventeenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '17': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='eighteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-eighteenSection' 
              aria-expanded="false" 
              aria-controls='panel-eighteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-eighteenSection' className="accordion-collapse collapse" aria-labelledby='eighteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '18': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='nineteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-nineteenSection' 
              aria-expanded="false" 
              aria-controls='panel-nineteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-nineteenSection' className="accordion-collapse collapse" aria-labelledby='nineteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '19': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentySection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentySection' 
              aria-expanded="false" 
              aria-controls='panel-twentySection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-twentySection' className="accordion-collapse collapse" aria-labelledby='twentySection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '20': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyOneSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyOneSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyOneSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
              <RenderStatusPill status={status} />
            </div>
          </h2>
          <div id='panel-twentyOneSection' className="accordion-collapse collapse" aria-labelledby='twentyOneSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
  }

  return allAccordions[index]
}


export default function AccordionWrapper({index, children, status, title}: AccordionWrapperProps) {
  return getIndexedAccordion(index, children, status, title)
}