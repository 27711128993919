const API_ENDPOINTS = {
  TOTAL_EMISSION: '/total-emission',
  DEPARTMENTS: '/departments',
  REDUCTION_INITIATIVES: '/reduction-initiative',
  FROM_DATE: 'from_date',
  TO_DATE: 'to_date',
  CATEGORY: 'category',
  SUB_CATEGORY: 'sub_category',
  IS_ACTIVE: 'isActive',
  IS_SCALED: 'is_scaled',
  BY_AVERAGE: 'by_average',
  START_DATE: 'start_date', 
  END_DATE: 'end_date', 
}

export default API_ENDPOINTS
