import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../store/store'
import { updateLeaderboardCategoryFilter } from '../../../store/slices/filters'

export const LeaderboardCategoryFilter = () => {
  const categoriesFilter = ["department", "division", "location"];
  const dispatch = useDispatch();
  const {filterLeaderboardCategory} = useSelector((state: RootState) => state.filters);

  const handleFilterLeaderboardCategory = (value: string) => {
    dispatch(updateLeaderboardCategoryFilter(value));
  }

  return (
    <div className='d-flex mx-0 px-0 mb-6'>
    {
      categoriesFilter.map((value:string) => (
      <div key={value} className='justify-content-end' style={{marginRight: '10px'}}>
        <button
          type='button'
          // disabled={!loading}
          className={`${filterLeaderboardCategory === value ? 'custom-primary-delete-button-v2' : 'custom-outline-button-v2'} m-0 px-10`}
          style={{
            borderRadius: '8px',
            background: filterLeaderboardCategory === value ? 'linear-gradient(to bottom, #604A80, #3A2F4C)' : '#fff',
            border: '1px solid #604A80',
            color: filterLeaderboardCategory === value ? '#fff' : '#604A80',
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleFilterLeaderboardCategory(value);
          }}
        >
          {value.toUpperCase()}
        </button>
      </div>
      ))
    }
    </div>
  )
}
