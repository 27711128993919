import {createSlice} from '@reduxjs/toolkit'

export type AsideMenuType = {
  isOpenEmissionsSec: boolean
  isOpenForestSec: boolean
  isOpenEngagementSec: boolean 
}

const initialState: AsideMenuType = {
  isOpenEmissionsSec: false,
  isOpenForestSec: false,
  isOpenEngagementSec: false,
}

export const asideMenuSlice = createSlice({
  name: 'asideMenu',
  initialState,
  reducers: {
    setIsOpenEmissionsSec: (state, action) => {
      state.isOpenEmissionsSec = action.payload
    },
    setIsOpenForestSec: (state, action) => {
      state.isOpenForestSec = action.payload
    },
    setIsOpenEngagementSec: (state, action) => {
      state.isOpenEngagementSec = action.payload
    },
  },
})

export const {setIsOpenEmissionsSec, setIsOpenForestSec, setIsOpenEngagementSec} = asideMenuSlice.actions

export default asideMenuSlice.reducer
