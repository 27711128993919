/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { commaSeparatedFormat } from '../../../utils/helpers'

type EmissionDataType = {
  title: string
  emission: number
  percentage_contribution: number
}

type Props = {
  data: Array<EmissionDataType> | []
}

const Chart = ({data}: any) => (
  <div className='card-body pt-3 mb-3 px-2'>
    {data.topThreeItems.map((item:any, index:number)=>{
      const config = data.getConfig(item, index);

      return(
        <div key={`${item.emission}${item.percentage_contribution}${config.heading}`} className='flex-1 mx-4 mt-8'>
          <div className='d-flex align-items-center justify-content-between flex-1'>
            <p className='category-location-heading' style={{color: 'black'}}>{config.heading}</p>
            <p className='category-location-value'>{commaSeparatedFormat(Math.round(item.emission))}<span style={{color:"rgba(141, 141, 141, 1)"}}> kgCO2</span></p>
          </div>
            
          <div style={{backgroundColor:config.bgColor, borderRadius: 0}} className='h-10px mt-0 w-100 mb-3 '>
            <div
              className='bg-progress-blue h-10px'
              role='progressbar'
              style={{
                width: `${item.percentage_contribution}%`,
                borderRadius: 0,
                backgroundImage: `linear-gradient(to right, ${config.gradientColor[0]}, ${config.gradientColor[1]})`
              }}
            />
        </div>
        </div>
    )})}
  </div>
)

const TopCategoryEmission: React.FC<Props> = ({
  data,
}) => {
  const sortedData = [...data].sort((a, b) => b.emission - a.emission)
  const topThreeItems = sortedData.slice(0, 4)

  const gradientColors = [['#37287B', '#976ED6'], ['#7354B3', '#BB8FFF'], ['#B78DFB', '#5DC3D4']]

  const getConfig = (item: EmissionDataType, index: number) => ({
    heading: item?.title,
    gradientColor: gradientColors[index%3],
    bgColor: '#E0E0E0'
  })

  return (
    <div className='card mb-0 p-2 bordered-grey'>
      {/* begin::Body */}
      <Chart data={{topThreeItems, getConfig}} />
      {/* end::Body */}
    </div>
  )
}

export {TopCategoryEmission}
