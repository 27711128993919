import { useState } from 'react'
import { useSelector } from 'react-redux'
import LocationEmissionTable from './components/location-emission-table'
import { DownloadIcon } from '../../../_metronic/assets/icons'
import ScopeTwoEmissionTable from './components/scope-two-emission-table'
import { downloadScopeTwoReportCSV } from '../../utils/helpers'
import { RootState } from '../../store/store'
import { FilterDataType } from './components/filter-data-type'
import LockWrapper from '../../../_metronic/partials/components/lock-wrapper/LockWrapper'

const scopeTwoTemplateLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/Scope2-Report.xlsx'

const ScopeTwoPage = () => {
  const {surveyBasedData, lockedScreens} = useSelector((state: RootState) => state.profile)

  const [filterDataType, setFilterDataType] = useState<string>("Survey Based");
  const categories = ["Survey Based", "Data Based"];

  return (
    <LockWrapper isLocked={lockedScreens.scopeTwoPage} description='This page displays information about Scope 2 emissions and its details. For more details, contact support. '>
      <div className="blank-page-view pt-6 pb-16 px-6">
        <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
          <h1 className='m-0' style={{fontWeight: 600}}>SCOPE 2</h1>
          {/* <button
            type='button'
            className='custom-primary-delete-button m-0 d-flex align-items-center'
            style={{padding: '8px 30px', fontWeight: 600}}
            onClick={()=> downloadScopeTwoReportCSV({categoryData: dashboardData?.scope2CategoryData || [], locationData: dashboardData?.scope2LocationEmissionData || []})}
            >
            <p className='mb-0 mx-2'>DOWNLOAD REPORT</p> <DownloadIcon height={18} width={18} />
          </button> */}
          <div className='d-flex align-items-center'>
            <FilterDataType filterDataType={filterDataType} setFilterDataType={setFilterDataType} categories={categories} />
            {
              surveyBasedData?.scope2CategoryData?.length > 0 && (
                <a
                  href={scopeTwoTemplateLink}
                  className='custom-primary-delete-button my-0'
                  style={{padding: '4px 8px', marginRight: 0, marginLeft: 20, fontWeight: 600, textDecoration: 'none'}}
                  >
                  <DownloadIcon height={24} width={24} />
                </a>
              )
            }
          </div>
        </div>

        <div className='mt-4'>
          <ScopeTwoEmissionTable listData={surveyBasedData?.scope2CategoryData || []} />
        </div>

        <div className='mt-12'>
          <h3 className='mt-0 mb-4'>LOCATION</h3>
          <LocationEmissionTable listData={surveyBasedData?.scope2LocationEmissionData || []} />
        </div>
      </div>
    </LockWrapper>
  )
}

export default ScopeTwoPage
