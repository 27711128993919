import React, {useEffect, useState} from 'react';

import { commaSeparatedFormat } from '../../../../utils/helpers';

type Props = {
  title: string;
  value: number | string;
  unit?: string;
  backgroundColor?: string;
  color?: string;
  isMarginBottom?: boolean;
  disableUnit?: boolean;
}

const HorizontalHeaderCard: React.FC<Props> = (props) => {
  const { title, value, unit = '', backgroundColor = "#ffffff", color = "#000000", isMarginBottom = true, disableUnit = false } = props;

  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (value === 0 || value === '0' || value === '0.0') {
      setDisplayValue('--');
    } else if (value) {
      setDisplayValue(commaSeparatedFormat(value));
    } else {
      setDisplayValue('--');
    }
  }, [value]);

  return (
    <div className={`horizontal-header-card ${isMarginBottom ? 'mb-6' : ''}`} style={{backgroundColor, color}}>
      <h4 className='m-0' style={{color}}>{title}</h4>
      <h4 className='m-0' style={{color}}>{displayValue} {disableUnit ? '' : unit}</h4>
    </div>
  )
}

export default HorizontalHeaderCard;