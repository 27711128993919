/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AccordionDivProps } from "../types";
import { RenderStatusPill } from "./render-status-pill";
import { InputNumber } from "../../../../ui/components/input-text";
import { Dropdown } from "../../../../ui/components/dropdown";
import { setScopeSurveyFormField, setSectionStatus, FORM_FIELDS, SectionStatus } from "../../../../store/slices/forms/scopeSurveyForm";
import { RootState } from "../../../../store/store";
import { usePostManualYearCumulativeSurveyMutation } from "../../store/api";
import { updateNotification } from "../../../../store/slices/notifier";
import { NotificationType } from "../../../../../_metronic/partials/components/types";
import { ManualYearCumulativeSurveyHeavyTravelerDetailsResponseType, ManualYearCumulativeSurveyRequestType } from "../../store/type";
import { useGetProfileDetailsQuery } from "../../../../routing/store/api";
import { updateProfile } from "../../../../store/slices/profile";
import { validateHeavyTraveler } from "../validation";

const AccordionDivFour = (props: AccordionDivProps) => {
  const {data} = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.scopeSurveyForm);
  const {data: profileDetails, refetch: refetchUserProfileData} = useGetProfileDetailsQuery();
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const {surveyInputData} = useSelector((state: RootState) => state.profile)

  const [postSurvey, {isSuccess: isSuccessPostSurvey, isError: isErrorPostSurvey}] = usePostManualYearCumulativeSurveyMutation();

  useEffect(() => {
    if (profileDetails) {
      dispatch(updateProfile(profileDetails))
    }
  }, [profileDetails])

  useEffect(() => {
    if (isSuccessPostSurvey) {
      dispatch(
        updateNotification({
          message: 'Professional air commute - heavy traveler details submitted successfully',
          type: NotificationType.SUCCESS
        })
      );
    }
  }, [isSuccessPostSurvey]);

  useEffect(() => {
    if (isErrorPostSurvey) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      );
    }
  }, [isErrorPostSurvey]);

  const handleSubmit = async () => {
    // Reset validation states
    setIsValid(true);
    setErrorMessage("");

    try {
      // Transform form data to API format
      const payload: ManualYearCumulativeSurveyRequestType = {
        office_electricity_consumption_details: surveyInputData?.officeElectricityConsumptionDetails?.map((office: any) => ({
          geo_id: office?.geoId || 0,
          country: office?.country?.name || '',
          state: office?.state?.name || '',
          city: office?.city?.name || '',
          area_sq_ft: office?.areaSqFt || 0,
          no_of_employees: office?.noOfEmployees || 0,
          avg_wfh_days_per_week_per_employee: office?.avgWfhDaysPerWeekPerEmployee || 0,
          is_igbc_certified: office?.isIgbcCertified ? true : false,
          is_renewable_energy_sourced: office?.isRenewableEnergySourced ? true : false
        })) || [],
        employee_commute_details: {
          no_of_company_owned_vehicles: surveyInputData?.employeeCommuteDetails?.noOfCompanyOwnedVehicles || 0,
          avg_km_per_day_per_vehicle: surveyInputData?.employeeCommuteDetails?.avgKmPerDayPerVehicle || 0,
          no_of_employee_using_pvt_vehicles: surveyInputData?.employeeCommuteDetails?.noOfEmployeeUsingPvtVehicles || 0,
          avg_km_per_employee_per_day: surveyInputData?.employeeCommuteDetails?.avgKmPerEmployeePerDay || 0
        },
        light_traveler_details: {
          no_of_employee_in_light_travels: surveyInputData?.lightTravelerDetails?.noOfEmployeeInLightTravels || 0,
          local_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || 0,
          local_travel_class: String(surveyInputData?.lightTravelerDetails?.localTravelClass || 'Economy'),
          medium_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || 0,
          medium_travel_class: String(surveyInputData?.lightTravelerDetails?.mediumTravelClass || 'Economy'),
          long_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || 0,
          long_travel_class: String(surveyInputData?.lightTravelerDetails?.longTravelClass || 'Economy')
        },
        // SETTING HEAVY TRAVELER DETAILS
        heavy_traveler_details: {
          no_of_employee_in_heavy_travels: formState.numberOfHeavyTravelers || 0,
          local_avg_round_trip_per_person_per_quarter: formState.heavyTravelersLocalTrips || 0,
          local_travel_class: formState.heavyTravelersLocalClass || '',
          medium_avg_round_trip_per_person_per_quarter: formState.heavyTravelersMediumTrips || 0,
          medium_travel_class: formState.heavyTravelersMediumClass || '',
          long_avg_round_trip_per_person_per_quarter: formState.heavyTravelersLongTrips || 0,
          long_travel_class: formState.heavyTravelersLongClass || ''
        }
      };

      // Validate required fields
      const isValidForm = validateHeavyTraveler(payload.heavy_traveler_details)

      if (!isValidForm.isValid) {
        setIsValid(false)
        setErrorMessage(isValidForm.errorMessage)
        return
      }

      // Submit the data
      const response = await postSurvey(payload).unwrap();
      await refetchUserProfileData();
      
    } catch (error) {
      console.error('Error submitting survey:', error);
      setIsValid(false);
      setErrorMessage("Failed to submit survey. Please try again.");
    }
  };

  const handleFieldChange = (field: FORM_FIELDS, value: any) => {
    dispatch(setScopeSurveyFormField({field, data: value}));

    // Create updated state with new value
    const updatedState = {
      ...formState,
      [field]: value
    };

    // Check status after field update using updated state
    const fieldsToCheck = [
      updatedState.numberOfHeavyTravelers,
      updatedState.heavyTravelersLocalTrips,
      updatedState.heavyTravelersLocalClass,
      updatedState.heavyTravelersMediumTrips,
      updatedState.heavyTravelersMediumClass,
      updatedState.heavyTravelersLongTrips,
      updatedState.heavyTravelersLongClass
    ];

    const isStarted = fieldsToCheck.some(fieldValue => 
      fieldValue !== '' && fieldValue !== undefined
    );

    const isCompleted = fieldsToCheck.every(fieldValue => 
      fieldValue !== '' && fieldValue !== undefined
    );

    let status: SectionStatus = "not-started";
    // if (isCompleted) {
    //   status = "completed";
    // } else 
    if (isStarted) {
      status = "in-progress";
    }

    dispatch(setSectionStatus({
      section: "heavyTravelersStatus",
      status
    }));
  };

  const travelClassOptions = [
    {label: 'Economy', value: 'Economy'},
    {label: 'Premium Economy', value: 'Premium Economy'},
    {label: 'Business', value: 'Business'},
    {label: 'First Class', value: 'First Class'}
  ];

  return (
    <div className="accordion bordered-grey" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
      <div className="accordion-item" style={{border: 'none'}}>
        <h2 className="accordion-header py-2" id='fourSection'>
          <button 
            className="accordion-button collapsed fs-3 bold bg-white" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target='#panel-fourSection' 
            aria-expanded="false" 
            aria-controls='panel-fourSection'
          >
            <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{data?.title}</p>
          </button>
          <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
            <RenderStatusPill status={data?.status} />
          </div>
        </h2>
        <div id='panel-fourSection' className="accordion-collapse collapse" aria-labelledby='fourSection'>
          <div className="accordion-body">
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Number of Heavy Travelers'
                  value={formState.numberOfHeavyTravelers}
                  onChange={(v) => handleFieldChange('numberOfHeavyTravelers', v)}
                  placeholder='Enter Number of Employees'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Local (<3h): Avg Trips/Person/Quarter'
                  value={formState.heavyTravelersLocalTrips}
                  onChange={(v) => handleFieldChange('heavyTravelersLocalTrips', v)}
                  placeholder='Enter Average Trips'
                />
              </div>
              <div className='col-4'>
                <Dropdown
                  label='Local Travel Class'
                  options={travelClassOptions}
                  onChange={(v) => handleFieldChange('heavyTravelersLocalClass', v)}
                  selectedValue={formState.heavyTravelersLocalClass}
                  placeholder='Select Travel Class'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Medium (4-7h): Avg Trips/Person/Quarter'
                  value={formState.heavyTravelersMediumTrips}
                  onChange={(v) => handleFieldChange('heavyTravelersMediumTrips', v)}
                  placeholder='Enter Average Trips'
                />
              </div>
              <div className='col-4'>
                <Dropdown
                  label='Medium Travel Class'
                  options={travelClassOptions}
                  onChange={(v) => handleFieldChange('heavyTravelersMediumClass', v)}
                  selectedValue={formState.heavyTravelersMediumClass}
                  placeholder='Select Travel Class'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Long (>7h): Avg Trips/Person/Quarter'
                  value={formState.heavyTravelersLongTrips}
                  onChange={(v) => handleFieldChange('heavyTravelersLongTrips', v)}
                  placeholder='Enter Average Trips'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <Dropdown
                  label='Long Distance Travel Class'
                  options={travelClassOptions}
                  onChange={(v) => handleFieldChange('heavyTravelersLongClass', v)}
                  selectedValue={formState.heavyTravelersLongClass}
                  placeholder='Select Travel Class'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-12 d-flex justify-content-end'>
                {!isValid && (
                  <p className="text-danger mt-6 mb-0 mx-6" style={{fontSize: '14px'}}>
                    {errorMessage}
                  </p>
                )}
                <button
                  type='button'
                  className='remove-button-styles fs-5'
                  style={{
                    borderRadius: 10,
                    color: 'white',
                    backgroundColor: '#0491BD',
                    border: 'none',
                    padding: '8px 30px',
                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                    fontWeight: 500
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionDivFour
