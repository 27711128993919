import React from 'react'
import { useSelector } from 'react-redux'

import { DivisionWrapper } from '../divisions/DivisionWrapper'
import { DepartmentWrapper } from '../departments/DepartmentWrapper'
import { LocationWrapper } from '../locations/LocationWrapper'
import LockWrapper from '../../../../_metronic/partials/components/lock-wrapper/LockWrapper'
import { RootState } from '../../../store/store'

type Props = {
  month: string
  category: string
}

export function DashboardDetails({month, category}: Props) {
  const {lockedScreens} = useSelector((state: RootState) => state.profile)
  if (category==='Division') {
    return <LockWrapper isLocked={lockedScreens?.detailsPage} description='This page shows emission data'>
      <DivisionWrapper month={month} />
    </LockWrapper>
  } if (category==='Department') {
    return <LockWrapper isLocked={lockedScreens?.detailsPage} description='This page shows emission data'>
      <DepartmentWrapper month={month} />
    </LockWrapper>
  }
  return <LockWrapper isLocked={lockedScreens?.detailsPage} description='This page shows emission data'>
    <LocationWrapper month={month} />
  </LockWrapper>
}
