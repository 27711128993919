import { IconArrowDown } from "../../../assets/icons";
import { IconArrowUp } from "../../../assets/icons";

type CollapsibleSectionProps = {
  title: string;
  icon: React.ReactNode;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
}

export default function CollapsibleSection(props: CollapsibleSectionProps) {
  const {title, icon, isOpen, toggleOpen, children} = props;
  return (
    <div className='menu-item py-3 px-4 mb-2' style={{marginLeft: '5px', marginRight: '10px', backgroundColor: '#121527', borderRadius: '5px'}}>
      <button type='button' className='py-2 remove-btn-styles d-flex align-items-center justify-content-between w-100' onClick={() => toggleOpen(!isOpen)} style={{marginRight: '15px'}}>
        <div className='d-flex align-items-center my-0'>
          {icon && <span className='menu-icon svg-icon-2' style={{marginRight: '15px'}}>{icon}</span>}
          <p className='menu-title m-0' style={{color: isOpen ? 'white' : '#b6b9c8'}}>{title}</p>
        </div>
        {
          isOpen ? <IconArrowUp color='white' /> : <IconArrowDown color='#b6b9c8' />
        }
      </button>
      {isOpen && <hr className='mb-0 mt-2' style={{color: 'white', height: '1px'}} />}
      <div className='m-0 mb-2 mt-2' style={{display: isOpen ? 'block' : 'none', paddingLeft: '10px'}}>
        {children}
      </div>
    </div>
  )
}