import api from '../../../services/api'
import baseApiv2 from '../../../services/api'
import {MethodType} from '../../../services/constants'
import {queryStringBuilder} from '../../../utils/querStringBuilder'
import API_ENDPOINTS from './apiPaths'

const dashboardScopeApisV2 = baseApiv2.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentsList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          ...(params?.isActive ? [{name: API_ENDPOINTS.IS_ACTIVE, value: params?.isActive}] : [])
        ])
        return {
          url: `${API_ENDPOINTS.DEPARTMENTS}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: any) => response?.data,
    }),
  }),
})

const dashboardScopeApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getTotalEmissionsData: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
          {name: API_ENDPOINTS.SUB_CATEGORY, value: params.sub_category},
          {name: API_ENDPOINTS.IS_SCALED, value: params.is_scaled},
          {name: API_ENDPOINTS.BY_AVERAGE, value: params.by_average},
        ])
        return {
          url: `${API_ENDPOINTS.TOTAL_EMISSION}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: any) => response?.data,
    }),
    getReductionInitiatives: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.REDUCTION_INITIATIVES}`,
        method: MethodType.get,
      }),
      transformResponse: (response: any) => response?.data,
    }),
    addReductionInitiatives: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.REDUCTION_INITIATIVES}`,
        method: MethodType.post,
        data: params,
      })
    }),
    updateReductionInitiatives: builder.mutation({
      query: (params) => {
        const {id, ...updateData} = params
        return {
          url: `${API_ENDPOINTS.REDUCTION_INITIATIVES}/${id}`,
          method: MethodType.put,
          data: updateData
        }
      }
    }),
  }),
})

export const {
  useLazyGetTotalEmissionsDataQuery,
  useLazyGetReductionInitiativesQuery,
  useAddReductionInitiativesMutation,
  useUpdateReductionInitiativesMutation
} = dashboardScopeApis

export const {
  useLazyGetDepartmentsListQuery,
} = dashboardScopeApisV2

