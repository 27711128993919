import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RoutesPath } from '../../routing/RoutesPath'
import CustomDonutGapChart from './charts/custom-donut-gap-chart'
import ReductionInitiativesTable from './components/reduction-initiatives-table'
import CircularGaugeRecaptureChart from './charts/circular-guage-recapture-chart'
import { updateProfile } from '../../store/slices/profile'
import { useGetProfileDetailsQuery } from '../../routing/store/api'
import { RootState } from '../../store/store'
import ModalAddReductionInitiative from './components/modal-add-reduction-initiative'
import LockWrapper from '../../../_metronic/partials/components/lock-wrapper/LockWrapper'
import { useLazyGetReductionInitiativesQuery } from './store/api'

const CHART_HEIGHT = 270;
const CHART_WIDTH = 270;

const styles: Record<string, React.CSSProperties> = {
  recaptureMarker: {
    width: '20px',
    height: '6px',
    borderRadius: '3px',
    // backgroundColor: '#58AF86',
    background: 'linear-gradient(to right, #58AF86, #87D4F9)',
    marginRight: '4px'
  },
  recaptureText: {
    fontSize: '12.5px'
  }
} as const

const ProjectedEmissionsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {surveyBasedData, lockedScreens} = useSelector((state: RootState) => state.profile)

  const [getReductionInitiatives, {data: reductionInitiativesData, isLoading: isLoadingGetReductionInitiatives}] = useLazyGetReductionInitiativesQuery()

  const [totalScopeEmissionsDonutData, setTotalScopeEmissionsDonutData] = useState<any[]>([])
  const [projected2030EmissionsDonutData, setProjected2030EmissionsDonutData] = useState<any[]>([])
  const [projected2040EmissionsDonutData, setProjected2040EmissionsDonutData] = useState<any[]>([])
  const [projected2050EmissionsDonutData, setProjected2050EmissionsDonutData] = useState<any[]>([])
  const [totalScopeEmissionsRecaptureData, setTotalScopeEmissionsRecaptureData] = useState<any>({})
  const [totalEmissionValue, setTotalEmissionValue] = useState<number>(1)
  const [isOpenModalAddReductionInitiative, setIsOpenModalAddReductionInitiative] = useState<boolean>(false)
  const [localStorageReductionInitiatives, setLocalStorageReductionInitiatives] = useState<any[]>([])
  const [reductionInitiatives, setReductionInitiatives] = useState<any[]>([])

  useEffect(() => {
    getReductionInitiatives({})
  }, [])

  useEffect(() => {
    if (reductionInitiativesData?.initiatives) {
      setReductionInitiatives(reductionInitiativesData?.initiatives || [])
    }
  }, [reductionInitiativesData])

  useEffect(() => {
    const storedReductionInitiatives = localStorage.getItem('reductionInitiatives') ? JSON.parse(localStorage.getItem('reductionInitiatives') || '') : []
    setLocalStorageReductionInitiatives(storedReductionInitiatives)
  }, [isOpenModalAddReductionInitiative])

  const {data: profileDetails} = useGetProfileDetailsQuery()

  useEffect(() => {
    if (profileDetails && !surveyBasedData) {
      dispatch(updateProfile(profileDetails))
    }
  }, [profileDetails, surveyBasedData])

  const getTotalScopeEmissionsDonutData = (data: any) => (
    [
      {name: 'Scope 1', count: (data?.scope1 || 0)/1000},
      {name: 'Scope 2', count: (data?.scope2 || 0)/1000},
      {name: 'Scope 3', count: (data?.scope3 || 0)/1000}
    ]
  )

  const getTotalScopeEmissionsRecaptureData = (currentRecapture: any, projectedRecapture: any) => {
    if (!projectedRecapture) return {
      current: currentRecapture || 0,
      '2030': 0,
      '2040': 0,
      '2050': 0
    }
    return {
      current: currentRecapture || 0,
      '2030': projectedRecapture["2030"]?.recapture || 0,
      '2040': projectedRecapture["2040"]?.recapture || 0,
      '2050': projectedRecapture["2050"]?.recapture || 0
    }
  }

  useEffect(() => {
    if (surveyBasedData?.totalEmissions && surveyBasedData?.projectedEmissionsData) {
      setTotalEmissionValue(((surveyBasedData?.totalEmissions?.total || 1)/1000))
      setTotalScopeEmissionsDonutData(getTotalScopeEmissionsDonutData(surveyBasedData?.totalEmissions))
      setProjected2030EmissionsDonutData(getTotalScopeEmissionsDonutData(surveyBasedData?.projectedEmissionsData?.["2030"] || {}))
      setProjected2040EmissionsDonutData(getTotalScopeEmissionsDonutData(surveyBasedData?.projectedEmissionsData?.["2040"] || {}))
      setProjected2050EmissionsDonutData(getTotalScopeEmissionsDonutData(surveyBasedData?.projectedEmissionsData?.["2050"] || {}))
      setTotalScopeEmissionsRecaptureData(getTotalScopeEmissionsRecaptureData(surveyBasedData?.totalEmissions?.recapture, surveyBasedData?.projectedEmissionsData))
    }
  }, [surveyBasedData])

  return (
    <LockWrapper isLocked={lockedScreens.projectedEmissionsPage} description='This page displays information about Scope 1, Scope 2 and Scope 3 emissions and its details. For more details, contact support. '>
      <div className="blank-page-view pt-8 pb-16 px-8">
        <div className='back-header-btn'>
            <button 
              type='button' 
              className='p-2 back-left-arrow-btn-wrap'
              onClick={()=>{navigate(RoutesPath.DASHBOARD.ROOT)}}
            >
            <div className='back-left-arrow-btn' /> 
          </button>
        </div>
        <div className='p-0 m-0 mt-4'>
          <h1 className='m-0' style={{fontWeight: 600}}>PROJECTED EMISSIONS</h1>
        </div>
        <div className='mt-2 d-flex justify-content-evenly flex-wrap'>
          <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
            <h3 style={{fontWeight: 600, textAlign: 'right'}}>CURRENT</h3>
            <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
              <CustomDonutGapChart data={totalScopeEmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={totalEmissionValue} zIndex={2} />
              <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
                <div style={styles.recaptureMarker}  />
                <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData.current}</span> tCO2e)</span></p>
              </div>
              <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '47.5%', left: '51%'}}>
                <CircularGaugeRecaptureChart 
                  donutChartData={{data: totalScopeEmissionsDonutData, totalValue: totalEmissionValue}} 
                  percentage={(totalScopeEmissionsRecaptureData.current/totalEmissionValue)*100} 
                  data={totalScopeEmissionsRecaptureData.current} 
                  chartHeight={`${CHART_HEIGHT * 1.03}px`} 
                />
              </div>  
            </div>
          </div>
          <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
            <h3 style={{fontWeight: 600, textAlign: 'right'}}>2030</h3>
            <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
              <CustomDonutGapChart data={projected2030EmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={totalEmissionValue} zIndex={2} />
              <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
                <div style={styles.recaptureMarker}  />
                <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData['2030']}</span> tCO2e)</span></p>
              </div>
              <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '46.6%', left: '50.8%'}}>
                <CircularGaugeRecaptureChart 
                  donutChartData={{data: projected2030EmissionsDonutData, totalValue: totalEmissionValue}} 
                  percentage={(totalScopeEmissionsRecaptureData['2030']/totalEmissionValue)*100} 
                  data={totalScopeEmissionsRecaptureData['2030']} 
                  chartHeight={`${CHART_HEIGHT * 1.03}px`} 
                />
              </div>  
            </div>
          </div>
          <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
            <h3 style={{fontWeight: 600, textAlign: 'right'}}>2040</h3>
            <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
              <CustomDonutGapChart data={projected2040EmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={500} zIndex={2} />
              <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
                <div style={styles.recaptureMarker}  />
                <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData['2040']}</span> tCO2e)</span></p>
              </div>
              <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '46.5%', left: '50.4%'}}>
                <CircularGaugeRecaptureChart 
                  donutChartData={{data: projected2040EmissionsDonutData, totalValue: totalEmissionValue}} 
                  percentage={(totalScopeEmissionsRecaptureData['2040']/totalEmissionValue)*100} 
                  data={totalScopeEmissionsRecaptureData['2040']} 
                  chartHeight={`${CHART_HEIGHT * 1.03}px`} 
                />
              </div>  
            </div>
          </div>
          <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
            <h3 style={{fontWeight: 600, textAlign: 'right'}}>2050</h3>
            <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
              <CustomDonutGapChart data={projected2050EmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={500} zIndex={2} />
              <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
                <div style={styles.recaptureMarker}  />
                <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData['2050']}</span> tCO2e)</span></p>
              </div>
              <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '47.2%', left: '50.2%'}}>
                <CircularGaugeRecaptureChart 
                  donutChartData={{data: projected2050EmissionsDonutData, totalValue: totalEmissionValue}} 
                  percentage={(totalScopeEmissionsRecaptureData['2050']/totalEmissionValue)*100} 
                  data={totalScopeEmissionsRecaptureData['2050']} 
                  chartHeight={`${CHART_HEIGHT * 1.03}px`} 
                />
              </div>  
            </div>
          </div>
        </div>
        <div className='mt-14'>
          <h2 className='m-0' style={{fontWeight: 600}}>REDUCTION INITIATIVES</h2>
          {
            // surveyBasedData?.reductionInitiatives?.length > 0 ?
            localStorageReductionInitiatives?.length > 0 ? 
              <>
                {/* <ReductionInitiativesTable listData={surveyBasedData?.reductionInitiatives} /> */}
                <ReductionInitiativesTable listData={localStorageReductionInitiatives} />
              </>
            :
              <div className='d-flex justify-content-center align-items-center bordered-grey mt-4' style={{height: '140px'}}>
                <p className='m-0' style={{fontSize: '18px', fontWeight: 600}}>No reduction initiatives found</p>
              </div>
          }
          <div className='d-flex flex-end'>
            <button
              type='button'
              className='custom-primary-delete-button mb-0 mt-4 mx-0 d-flex align-items-center'
              style={{padding: '2px 20px', fontWeight: 600}}
              onClick={() => setIsOpenModalAddReductionInitiative(true)}
              >
              <span className='m-0' style={{fontWeight: 800, fontSize: '20px'}}>+</span> 
              <p className='m-0 mx-2'>ADD MORE</p>
            </button>
          </div>
        </div>
        <div className='mt-2'>
          <button
            type='button'
            className='custom-primary-delete-button mb-0 mx-0'
            style={{padding: '8px 20px', fontWeight: 600}}
            onClick={()=> navigate(RoutesPath.FOREST.ROOT)}
            >
            RECAPTURE RESIDUAL EMISSIONS
          </button>
        </div>
        <ModalAddReductionInitiative show={isOpenModalAddReductionInitiative} onHide={() => setIsOpenModalAddReductionInitiative(false)} />
      </div>
    </LockWrapper>
  )
}

export default ProjectedEmissionsPage
