/* eslint-disable global-require */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesPath } from '../../../../routing/RoutesPath';
import { DownloadIcon, IconFilePurple } from '../../../../../_metronic/assets/icons';

type Props = {
  data: {
    name: string;
    url: string;
    date: string;
  }
}

const DownloadableDocsCard: React.FC<Props> = (props) => {
  const { data } = props;
  const navigate = useNavigate();

  return (
    <a 
      href={data.url}
      target="_blank"
      rel="noopener noreferrer"
      style={{minWidth: '150px', maxWidth: '25%'}} 
      className='downloadable-docs-card remove-button-styles position-relative'
      >
      <div className='d-flex flex-column gap-2 align-items-center pt-4'>
        <IconFilePurple height={58} width={58} />
        <h3 className='m-0 mt-4 mx-2 bold' style={{color: "#5B5B5B", wordWrap: 'break-word', lineBreak: 'anywhere'}}>{data.name}</h3>
        <p className='m-0 mx-2 fs-8' style={{color: "#5B5B5B"}}>{data.date}</p>
      </div>
      <div className='position-absolute' style={{top: '5px', right: '5px'}}>
        <DownloadIcon height={18} width={18} color="#888888" />
      </div>
    </a>
  )
}

export default DownloadableDocsCard;