import {createSlice} from '@reduxjs/toolkit'

type ReportOrgTag = {
  id: number,
  name: string,
  short_name: string,
  color: string,
}

export type CustomGroupCategoryType = "live" | "upcoming" | "completed";

export type ChallengesFilterType = "live" | "upcoming" | "draft" | "completed";

export type PollFilterType = "live" | "upcoming" | "draft" | "completed";

export type QuizzesFilterType = "all" | "live" | "upcoming" | "completed";

export type UploadScopesReportFilterType = "scope1" | "scope2";

export type UploadDataScopeOneSubcategoriesFilterType = "mobile_consumption" | "fugitive_emissions" | "stationary_combustion" | "other";

export enum UploadDataScopeOneSubcategoriesEnum {
  MOBILE_CONSUMPTION = "mobile_consumption",
  FUGITIVE_EMISSIONS = "fugitive_emissions",
  STATIONARY_COMBUSTION = "stationary_combustion",
  OTHER = "other"
}

export type UploadDataScopeTwoSubcategoriesFilterType = "location_based_method" | "market_based_method" | "other";

export enum UploadDataScopeTwoSubcategoriesEnum {
  LOCATION_BASED_METHOD = "location_based_method",
  MARKET_BASED_METHOD = "market_based_method",
  OTHER = "other"
}

export type FilterDataTypeToggleType = "Profile Based" | "Auto-tracking";

export enum FilterDataTypeToggleEnum {
  PROFILE_BASED = "Profile Based",
  AUTO_TRACKING = "Auto-tracking"
}

export const FilterDataTypeToggleCategories = ["Profile Based", "Auto-tracking"];

export type FilterType = {
  filterYear: string
  filterMonth: string
  filterDate: {
    startDate: string, 
    endDate: string
  }
  filterCategory: string
  filterLeaderboardTimePeriod: string
  filterLeaderboardCategory: string
  filterLeaderboardActivities: string[]
  filterDivSubCategory: string
  filterDeptSubCategory: string
  filterLocSubCategory: string
  filterExtrapolation: boolean
  filterCustomGroupCategory: CustomGroupCategoryType
  filterChallengesType: ChallengesFilterType
  filterPollType: PollFilterType
  filterQuizzesType: QuizzesFilterType
  filterReportsOrgTag: ReportOrgTag
  filterUploadScopesReport: "scope1" | "scope2"
  filterUploadDataScopeOneSubcategories: UploadDataScopeOneSubcategoriesFilterType
  filterUploadDataScopeTwoSubcategories: UploadDataScopeTwoSubcategoriesFilterType
  filterDashboardDataTypeToggle: FilterDataTypeToggleType
}

const initialState: FilterType = {
  filterYear: 'All',
  filterMonth: 'All',
  filterDate: {
    startDate: '', 
    endDate: ''
  },
  filterCategory: 'Department',
  filterLeaderboardTimePeriod: 'weekly',
  filterLeaderboardCategory: 'department',
  filterLeaderboardActivities: [],
  filterDivSubCategory: 'All', 
  filterDeptSubCategory: 'All',
  filterLocSubCategory: 'All', 
  filterExtrapolation: false, 
  filterCustomGroupCategory: 'live',
  filterChallengesType: 'live',
  filterPollType: 'live',
  filterQuizzesType: 'all',
  filterUploadScopesReport: 'scope1',
  filterUploadDataScopeOneSubcategories: UploadDataScopeOneSubcategoriesEnum.MOBILE_CONSUMPTION,
  filterUploadDataScopeTwoSubcategories: UploadDataScopeTwoSubcategoriesEnum.LOCATION_BASED_METHOD,
  filterReportsOrgTag: {
    id: 0,
    name: 'Enterprise Reports',
    short_name: 'Enterprise Reports',
    color: '#FFFFFF',
  },
  filterDashboardDataTypeToggle: FilterDataTypeToggleEnum.PROFILE_BASED,
}

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateDateFilter: (state, action) => {
      state.filterDate = action.payload
    },
    updateYearFilter: (state, action) => {
      if (state.filterYear !== action.payload) {
        state.filterMonth = 'All'
      }
      state.filterYear = action.payload
    },
    updateMonthFilter: (state, action) => {
      state.filterMonth = action.payload
      if (action.payload !== 'All') {
        state.filterExtrapolation = false
      }
    },
    updateDashboardDataTypeToggle: (state, action) => {
      state.filterDashboardDataTypeToggle = action.payload
      if (action.payload === FilterDataTypeToggleEnum.PROFILE_BASED) {
        state.filterMonth = 'All'
        state.filterYear = 'All'
      }
    },
    updateCategoryFilter: (state, action) => {
      state.filterCategory = action.payload
    },
    udpateLeaderboardTimePeriodFilter: (state, action) => {
      state.filterLeaderboardTimePeriod = action.payload
    },
    updateLeaderboardCategoryFilter: (state, action) => {
      state.filterLeaderboardCategory = action.payload
    },
    addLeaderboardActivitiesFilter: (state, action) => {
      state.filterLeaderboardActivities = [...state.filterLeaderboardActivities, action.payload]
    },
    removeLeaderboardActivitiesFilter: (state, action) => {
      state.filterLeaderboardActivities = state.filterLeaderboardActivities.filter(activity => activity !== action.payload)
    },
    resetLeaderboardActivitiesFilter: (state) => {
      state.filterLeaderboardActivities = []
    },
    selectAllLeaderboardActivitiesFilter: (state, action) => {
      state.filterLeaderboardActivities = action.payload;
    },
    updateCustomGroupCategoryCategoryFilter: (state, action: {payload: CustomGroupCategoryType}) => {
      state.filterCustomGroupCategory = action.payload;
    },
    updateChallengesFilterType: (state, action: {payload: ChallengesFilterType}) => {
      state.filterChallengesType = action.payload;
    },
    updatePollsFilterType: (state, action: {payload: PollFilterType}) => {
      state.filterPollType = action.payload;
    },
    updateQuizzesFilterType: (state, action: {payload: QuizzesFilterType}) => {
      state.filterQuizzesType = action.payload;
    },
    updateUploadScopesReportFilterType: (state, action: {payload: UploadScopesReportFilterType}) => {
      state.filterUploadScopesReport = action.payload;
    },
    updateUploadDataScopeOneSubcategoriesFilterType: (state, action: {payload: UploadDataScopeOneSubcategoriesFilterType}) => {
      state.filterUploadDataScopeOneSubcategories = action.payload;
    },
    updateUploadDataScopeTwoSubcategoriesFilterType: (state, action: {payload: UploadDataScopeTwoSubcategoriesFilterType}) => {
      state.filterUploadDataScopeTwoSubcategories = action.payload;
    },
    updateReportsOrgTag: (state, action: {payload: ReportOrgTag, type: string}) => {
      state.filterReportsOrgTag = action.payload
    },
    resetReportsOrgTag: (state) => {
      state.filterReportsOrgTag = {
        id: 0,
        name: 'Enterprise Reports',
        short_name: 'Enterprise Reports',
        color: '#FFFFFF',
      }
    },
    updateDivSubCateogryFilter: (state, action) => {
      state.filterDivSubCategory = action.payload
    },
    updateDeptSubCateogryFilter: (state, action) => {
      state.filterDeptSubCategory = action.payload
    },
    updateLocSubCateogryFilter: (state, action) => {
      state.filterLocSubCategory = action.payload
    }, 
    updateExtrapolationFilter: (state, action) => {
      state.filterExtrapolation = action.payload
    },
  },
})

export const {
  updateDateFilter, 
  updateYearFilter,
  updateMonthFilter, 
  updateCategoryFilter,
  udpateLeaderboardTimePeriodFilter,
  updateLeaderboardCategoryFilter,
  addLeaderboardActivitiesFilter,
  removeLeaderboardActivitiesFilter,
  resetLeaderboardActivitiesFilter,
  selectAllLeaderboardActivitiesFilter,
  updateDivSubCateogryFilter, 
  updateDeptSubCateogryFilter, 
  updateLocSubCateogryFilter, 
  updateExtrapolationFilter, 
  updateReportsOrgTag,
  resetReportsOrgTag,
  updateCustomGroupCategoryCategoryFilter,
  updateChallengesFilterType, 
  updatePollsFilterType,
  updateQuizzesFilterType,
  updateUploadScopesReportFilterType,
  updateUploadDataScopeOneSubcategoriesFilterType,
  updateUploadDataScopeTwoSubcategoriesFilterType,
  updateDashboardDataTypeToggle,
} = filterSlice.actions

export default filterSlice.reducer
