import React from 'react';
import { BarProps, LeaderboardGraphItem } from './types';

type PropsType = {
  leaderboard: LeaderboardGraphItem[]
}

const barColorConfig = (idx:number) => {
  let color = '#62909A';
  switch (idx) {
    case 0:
      color = '#62909A';
      break;
    case 1:
      color = '#7BA6B0';
      break;
    case 2:
      color = '#93BCC6';
      break;
    case 3:
      color = '#A8C9D1';
      break;
    case 4:
      color = '#BAD1D9';
      break;
    default:
      color = '#62909A';
      break;
  }
  return color;
}

const addBarHeight = (arr: LeaderboardGraphItem[]): BarProps[] => {
  let maximumValue = 0;
  for (let i = 0; i < arr.length; i += 1) {
    maximumValue = Math.max(maximumValue, arr[i].value)
  }
  const result: BarProps[] = [];
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    let height: number = maximumValue !== 0 ? Math.round((item.value / maximumValue) * 90) : 1;
    if (height < 1)
      height = 1; 
    result.push({
      label: item.label,
      value: item.value,
      height,
      color: barColorConfig(i)
    })
  }

  return result
}

const BarDiv = (props: BarProps) => {
  const {label, value, height, color} = props;
  return (
    <div key={label} style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '100%',
      zIndex: 1,
      }}>
      <p className='m-0 fs-6' style={{color: '#604A80', fontWeight: '800'}}>{value < 1 ? '<1' : Math.round(value)}</p>
      <div style={{background: 'linear-gradient(to bottom, #604A80, #3A2F4C)', borderRadius: '10px', height: `${height}%`, width: '60px', marginBottom: '24px'}} />
      <p className='m-0 position-absolute' style={{color: '#787878', fontWeight: '800'}}>{label.toUpperCase()}</p>
    </div>
  )
}

export const LeaderboardBarGraph = (props: PropsType) => {
  const {leaderboard} = props;
  const leaderboardData: BarProps[] = addBarHeight(leaderboard)

  return (
    <div className='d-flex justify-content-evenly bordered-grey position-relative mx-0 px-4 py-2 mb-6' style={{height: '280px'}}>
    {
      leaderboardData.map((item: BarProps) => (
        <BarDiv 
          key={item.label} 
          label={item.label} 
          value={item.value} 
          height={item.height}
          color={item.color}
        />
      ))
    }
    {
      leaderboardData.length === 0 &&
      <p style={{color: '#787878', fontSize: '16px', fontWeight: '600', alignSelf: 'center'}}>No data found!</p>
    }
      {/* DOTTED LINES IN GRAPH */}
      <div style={{
        position: 'absolute', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: "space-evenly", 
        height: '100%', 
        width: '100%', 
        top: '16px', 
        padding: '0px 30px'
        }}>
        <hr style={{borderTop: 'dotted 2px', borderColor: '#B9B9B9'}} />
        <hr style={{borderTop: 'dotted 2px', borderColor: '#B9B9B9'}} />
        <hr style={{borderTop: 'dotted 2px', borderColor: '#B9B9B9'}} />
        <hr style={{borderTop: 'dotted 2px', borderColor: '#B9B9B9'}} />
      </div>
    </div>
  )
}