/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {ActivityEmission} from '../types'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'
import { DownloadIcon } from '../../../assets/icons'
import ModalDownloadChart, { ChartType } from '../modal-download-charts/modal-download-chart'

// const CHART_LABEL_COLORS = ['#3699FF', '#86C1FF', '#BBDCFF'];
const CHART_LABEL_COLORS = ['#392F59', '#7B6CB2', '#A687CA'];

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title: string
  desctiption: string
  data?: Array<ActivityEmission> | undefined
}

const getCopyChartOptions = (chartOptions: ApexOptions, copychartref: any) => {
  const copyChartOptions: ApexOptions = {...chartOptions};

  if (copyChartOptions?.legend) copyChartOptions.legend.show = false;
  if (copyChartOptions?.chart?.height) copyChartOptions.chart.height = '250px';

  const copychart = new ApexCharts(copychartref.current, copyChartOptions);
  return copychart;
}

const Activities: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  title,
  desctiption,
  data,
}) => {
  const [downloadChartModalShow, setDownloadChartModalShow] = useState<boolean>(false);

  const chartref = useRef<HTMLDivElement | null>(null);
  const copychartref = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

  const [chartModalData, setChartModalData] = useState<any>({});

  const refreshMode = () => {
    if (!chartref.current || !data) {
      return undefined
    }

    const sortedData = [...data].sort((a, b) => b.emission - a.emission)
    const topThreeItems = sortedData.slice(0, 3)

    const chartData = {
      series: topThreeItems.map((item) => Math.round(item.emission)),
      labels: topThreeItems.map((item) => item.name.charAt(0).toUpperCase() + item.name.slice(1)),
    }

    const chartOptions: ApexOptions = {
      chart: {
        type: 'donut',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (value: number) => `${Math.round(value)}%`,

        style: {
          fontSize: '14px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 500,
          colors: ['#fff'], // Customize the label color
        },
      },
      colors: CHART_LABEL_COLORS,
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '45%',
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Nunito ,sans-serif',
                color: '#888',
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Nunito ,sans-serif',
                color: '#888',
                offsetY: 16,
                formatter: (value) => `${value}kg`,
              },
            },
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },

      series: chartData.series,
      labels: chartData.labels,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 0,
        markers: {
          width: 12,
          height: 12,
          radius: 4,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        itemMargin: {
          horizontal: 5,
        },
      },

      tooltip: {
        enabled: true, 
        y: {
          formatter: (val) => `${commaSeparatedFormat(parseFloat(val.toFixed(1)))} kgCO2`,
          title: {
              formatter: (seriesName) => `${seriesName} :`,
          },
        },
      }
    }
    const chart = new ApexCharts(chartref.current, chartOptions);
    // copy chartOptions for modal download preview
    const copychart = getCopyChartOptions(chartOptions, copychartref);

    chart.render();
    copychart.render();
    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart !== undefined) {
        ;(chart as ApexCharts).destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  useEffect(() => {
    if (data) {
      setChartModalData({
        data,
        labelColors: CHART_LABEL_COLORS,
        title: "Activities (Top 3)",
      });
    }
  }, [data]);

  return (
    <div className='card mb-xl-8 mt-2 bordered-grey'>
      {/* begin::Beader */}
      <div className='card-header border-0 pt-2'>
        <h3 className='card-title align-items-center flex-row'>
          <span className='card-label fw-bold fs-3 mb-1'>{title.toUpperCase()}</span>
          <span className='fw-semibold fs-7'>(TOP 3)</span>
        </h3>

        <button 
          type='button' 
          className='remove-btn-styles download-report-btn p-1'
          onClick={() => setDownloadChartModalShow(true)}
          // style={{display: 'none'}}
          >
          <DownloadIcon color='#5CADC9'/> 
        </button>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <img src={OpenIcon} alt='' />
          </button>
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body-chart d-flex flex-column mb-3'>
        <div className='flex-grow-1'>
          <div ref={chartref} className='mixed-widget-4-chart pt-6 pb-8' />
        </div>
      </div>
      {/* end::Body */}
      <div ref={copychartref} style={{display: 'none'}} />

      <ModalDownloadChart 
        show={downloadChartModalShow} 
        onHide={() => setDownloadChartModalShow(false)}
        chartref={copychartref}
        chartType={ChartType.DONUT}
        data={chartModalData}
        />
    </div>
  )
}

export {Activities}
