/* eslint-disable global-require */
import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useLazyGetHighlightsQuery } from './store/api';

import { HightlightRibbon } from '../../../_metronic/assets/icons';
import { getLocalStorageItem } from '../../utils/storage';
import { getYYYYDDMMFormat } from '../../utils/dateFormatter';
import { RootState } from '../../store/store';

// const dummyData = [
//   'Choose eco-friendly travel options and efficient errand planning to cut transportation emissions.', 
//   'Choose eco-friendly travel options and efficient errand planning to cut transportation emissions.',
//   'Choose eco-friendly travel options and efficient errand planning to cut transportation emissions.',
// ]

const Card1 = ({title, value}: any) => (
    <div className='highlights-card-wrapper'>
      <img className='highlight-round-img' src={require('../../../_metronic/assets/images/highlight-img1.png')} alt='img1' />
      <div className='px-3'>
        <h2 className='m-0' style={{display: 'inline', fontSize: '16px'}}>{title}</h2>
        <p style={{display: 'inline', marginLeft: value==='--'?'100px':'5px', fontSize: '14px'}}>{value}</p>
      </div>
    </div>
  )

const Card2 = ({title, value}: any) => (
    <div className='highlights-card-wrapper justify-content-between'>
      <div className='px-3'>
        <h2 className='m-0' style={{display: 'inline', fontSize: '16px'}}>{title}</h2>
        <p style={{display: 'inline', marginLeft: value==='--'?'100px':'5px', fontSize: '14px'}}>{value}</p>
      </div>
      <img className='highlight-round-img' src={require('../../../_metronic/assets/images/highlight-img2.png')} alt='img1' />
    </div>
  )

const Card3 = ({title, value}: any) => (
    <div className='highlights-card-wrapper'>
      <img className='highlight-round-img' src={require('../../../_metronic/assets/images/highlight-img3.png')} alt='img1' />
      <div className='px-3'>
        <h2 className='m-0' style={{display: 'inline', fontSize: '16px'}}>{title}</h2>
        <p style={{display: 'inline', marginLeft: value==='--'?'100px':'5px', fontSize: '14px'}}>{value}</p>
      </div>
    </div>
  )


const HightlightsDashboard = () => {
  const [getHighlights, {data: highlightsData}] = useLazyGetHighlightsQuery();

  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const {profile} = useSelector((state: RootState) => state.profile)
  const netZeroToDateLocal = getLocalStorageItem('ws-net-zero-to-date') || null;

  const getDateFilters = useCallback(() => {
    const currentDate = new Date()
      // const selectedMonth = parseInt(timePeriodFilter, 10)
      // const selectedYear =
      //   selectedMonth > currentDate.getUTCMonth()
      //     ? currentDate.getUTCFullYear() - 1
      //     : currentDate.getUTCFullYear()

    const fromDate = moment(liveOnDate).format('YYYY-MM-DD')

    let toDate = getYYYYDDMMFormat(currentDate)
    if (profile?.organization?.isWorkshopOrg) {
      toDate = netZeroToDateLocal || moment(profile?.organization?.workshopDate).add(90, 'days').format('YYYY-MM-DD')
    }

    return {
      fromDate,
      toDate,
    }
  }, [profile])
    
  useEffect(() => {
    if (profile) {
      getHighlights({
        ...({...getDateFilters()}),
      })
    }
  }, [profile]) 

  return (
    <>
      <HightlightRibbon width='92%' />
      <div className='highlights-all-cards-wrap'>
        <Card1 title='Onboarding:' value={highlightsData?.data?.onboarding || '--'} />
        <Card2 title='Recapture:' value={highlightsData?.data?.recapture || '--'} />
        <Card3 title='Emission:' value={highlightsData?.data?.emission || '--'} />
      </div>
    </>
  )
}

export default HightlightsDashboard
/* eslint-disable global-require */