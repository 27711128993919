import { useState } from 'react'
import { useSelector } from 'react-redux'
import { DownloadIcon } from '../../../_metronic/assets/icons'
import ScopeThreeEmissionTable from './components/scope-three-emission-table'
import { RootState } from '../../store/store'
import { FilterDataType } from './components/filter-data-type'
import LockWrapper from '../../../_metronic/partials/components/lock-wrapper/LockWrapper'

const scopeOneTemplateLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/Scope1-Report.xlsx'

const ScopeThreePage = () => {
  const {surveyBasedData, lockedScreens} = useSelector((state: RootState) => state.profile)
  const categories = ["Survey Based"];

  const [filterDataType, setFilterDataType] = useState<string>("Survey Based");

  return (
    <LockWrapper isLocked={lockedScreens.scopeThreePage} description='This page displays information about Scope 3 emissions and its details. For more details, contact support. '>
      <div className="blank-page-view pt-6 pb-16 px-6">
        <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
          <h1 className='m-0' style={{fontWeight: 600}}>SCOPE 3</h1>
          <div className='d-flex align-items-center'>
            <FilterDataType filterDataType={filterDataType} setFilterDataType={setFilterDataType} categories={categories} />
            {
              surveyBasedData?.scope3CategoryData?.length > 0 && (
                <a
                  href={scopeOneTemplateLink}
                  className='custom-primary-delete-button my-0'
                  style={{padding: '4px 8px', marginRight: 0, marginLeft: 20, fontWeight: 600, textDecoration: 'none'}}
                  >
                  <DownloadIcon height={24} width={24} />
                </a>
              )
            }
          </div>
        </div>

        <div className='mt-10'>
          <ScopeThreeEmissionTable data={surveyBasedData?.scope3CategoryData || []} />
        </div>  
      </div>
    </LockWrapper>
  )
}

export default ScopeThreePage