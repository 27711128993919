/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import '../styles.css'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'
import { CardsWidget2 } from './CardsWidget2'
import { NetZeroStatusChart } from './NetZeroStatusChart'
import { RootState } from '../../../../app/store/store'
import { FilterDataTypeToggleEnum, updateExtrapolationFilter } from '../../../../app/store/slices/filters'
import { RoutesPath } from '../../../../app/routing/RoutesPath'
import InfoHelperIcon from './InfoHelperIcon'

type Props = {
  totalEmissionValue?: number | undefined
  totalRecaptureValue?: number | undefined
  totalEmployeesOnboarded?: number | undefined
  totalEmployeesDashboard?: number | undefined
  totalEmployeesContributed?: number | undefined
  totalEmployees?: number | undefined 
  month?: String
  isPlansAvailable?: boolean | undefined
}

type CardProps = {
  label?: String | undefined
  title?: String | undefined
  value?: String | undefined
  borderClass?: String | undefined
  isAvailable?: boolean | undefined
}

const Card: React.FC<any> = ({label, title, value, borderClass}) => (
    <div className={`custom-header-card2 ${borderClass} `}>
      <div className='chc-title2 d-flex mb-2'>
        <span className='fs-6' style={{color: '#444444'}}>
          {(label!=='undefined') ? label?.toUpperCase() : '-'}
        </span>
        {/* <InfoHelperIcon 
          style={{right: '0px'}}
          content={<>
              <p className='m-0'>Net zero status is the comparison of daily emissions to daily recapture from your Forest.</p>
              <p className='m-0'>&lt; 100% - If the emissions are greater than the recapture </p>
              <p className='m-0'>100% - If the recapture is greater than or equal to the emissions</p> 
            </>} 
            /> */}
      </div>
      <div className='chc-content2 position-relative'>
        <span className='value hover-truncate-ellipsis' data-text={(title!=='undefined') ? title?.toString() : '-'} style={{display:'block', fontSize: '2rem', fontWeight: 800}}>
          {(title!=='undefined') ? title : '-'}
        </span>
        <span className='sub-label' style={{display: 'block', marginLeft: '0px'}}>
          {(value!=='NaN kgCO2') ? value : '0 kgCO2'}
        </span>
      </div>
    </div>
  )

const CardBlank: React.FC<any> = ({label, isAvailable, borderClass}) => {
  const navigate = useNavigate()

  return (
    <div className={`custom-header-card2 ${borderClass} pb-0`}>
      <div className='chc-title2 mb-0 d-flex'>
        <span className='fs-6' style={{color: '#444444'}}>
          {(label!=='undefined') ? label?.toUpperCase() : '-'}
        </span>
        {
          label === 'NET ZERO STATUS' && 
          <InfoHelperIcon 
            style={{left: '-250px'}}
            content={
              <>
                <p className='m-0'>Net zero status is the comparison of daily emissions to daily recapture from your Forest.</p>
                <p className='m-0'><span style={{fontWeight: '600'}}>&lt; 100%</span> - If the recapture is less than the emissions</p>
                <p className='m-0'><span style={{fontWeight: '600'}}>100%</span> - If the recapture is equal to the emissions</p> 
                <p className='m-0'><span style={{fontWeight: '600'}}>&gt; 100%</span> - If the recapture is greater than the emissions</p>
              </>
            } 
            />
        }
      </div>
      {
        isAvailable ? 
        <NetZeroStatusChart /> : 
        <div>
          <p style={{color: '#666666', fontSize: '13px', marginTop: '5px'}}>
            No Active Plans
          </p>
          <button
            type='button'
            className='custom-primary-delete-button m-0 mb-2 py-1 px-2'
            onClick={()=> navigate(RoutesPath.OUR_FOREST.ROOT)}
            >
            Explore Plans
          </button>
        </div>
      }
    </div>
  )
}

const HeaderWidgetsWrapper: React.FC<Props> = ({
  totalEmissionValue,
  totalRecaptureValue,
  totalEmployeesOnboarded,
  totalEmployeesDashboard,
  totalEmployeesContributed,
  totalEmployees,
  month, 
  isPlansAvailable
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const {profile, profileBasedData} = useSelector((state: RootState) => state.profile)
  const {filterExtrapolation, filterDashboardDataTypeToggle} = useSelector((state: RootState) => state.filters)

  const [cardWidOptions2, setCardWidOptions2] = useState({
    label: '', 
    value: '', 
    info: '',
    label2: '', 
    value2: '',
    info2: ''
  })

  const handleToggleSwitch = () => {
    dispatch(updateExtrapolationFilter(!filterExtrapolation))
  }

  const totalEmissionsValue = totalEmissionValue ? Math.round(totalEmissionValue) : 0;
  const totalRecapturesValue = totalRecaptureValue ? Math.round(totalRecaptureValue) : 0
  
  // const totalEmployees = totalEmployeesOnboarded ? Math.round(totalEmployeesOnboarded) : 0

  useEffect(() => {
    if (location.pathname.includes('/dashboard-emission') && filterDashboardDataTypeToggle === FilterDataTypeToggleEnum.PROFILE_BASED && profileBasedData?.length > 0) {
      // cardWidget2
      setCardWidOptions2({
        label: '💨 TOTAL EMISSIONS',
        value: commaSeparatedFormat(Math.round(profileBasedData[0]?.total || 0)),
        info: '',
        label2: '🌱 TOTAL RECAPTURED',
        value2: commaSeparatedFormat(totalRecapturesValue),
        info2: ''
      })
    } else {
      setCardWidOptions2({
        label: '💨 TOTAL EMISSIONS',
        value: commaSeparatedFormat(totalEmissionsValue),
        info: '',
        label2: '🌱 TOTAL RECAPTURED',
        value2: commaSeparatedFormat(totalRecapturesValue),
        info2: ''
      })
    }
  }, [profileBasedData, filterDashboardDataTypeToggle, totalEmissionValue, totalRecaptureValue])

  return (
    <div>
      {/* {
        (profile?.organization?.isExtrapolationEnabled === 1) &&
        <div className='extrapolation-banner bg-clr-blue'>
          {
            filterExtrapolation ? 
            <p className='m-0'>Displayed emission data is an <strong>extrapolation</strong>. Toggle to see the <strong>actual data</strong>.</p> :
            <p className='m-0'>Displayed emission data is based on <strong>actual employees</strong> using Mynzo. Toggle to see the <strong>extrapolated emissions</strong>.</p>
          }
          <label className="switch">
            <input type="checkbox" checked={filterExtrapolation} onChange={() => handleToggleSwitch()} disabled={month!=='All'} />
            <span className="slider round" />
          </label>
        </div>
      } */}
      <div className='custom-header bg-clr-blue'>
        <CardsWidget2 options={cardWidOptions2} />
        {/* <CardBlank label='NET ZERO STATUS' isAvailable={isPlansAvailable} borderClass='no-border-radius-rg' /> */}
        <Card label='EMPLOYEES ONBOARDED' title={`${commaSeparatedFormat(totalEmployeesOnboarded || 0)}/${commaSeparatedFormat(totalEmployeesDashboard || '-')}`} borderClass='no-border-radius-rg' />
        {/* <Card label='CONTRIBUTING EMPLOYEES' title={ totalEmployees ? `${commaSeparatedFormat(totalEmployeesContributed || 0)}/${commaSeparatedFormat(totalEmployees || '-')}` : `${commaSeparatedFormat(totalEmployeesContributed || 0)}`} borderClass='no-border-radius-lf' /> */}
        <Card label='CONTRIBUTING EMPLOYEES' title={`${commaSeparatedFormat(filterDashboardDataTypeToggle === FilterDataTypeToggleEnum.PROFILE_BASED && location.pathname.includes('/dashboard-emission') ? totalEmployeesOnboarded : totalEmployeesContributed || 0)}`} borderClass='no-border-radius-lf' />
      </div>
    </div>
  )
}

export {HeaderWidgetsWrapper}
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */