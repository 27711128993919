import React from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../store/store'
import { addLeaderboardActivitiesFilter, removeLeaderboardActivitiesFilter, resetLeaderboardActivitiesFilter, selectAllLeaderboardActivitiesFilter } from '../../../store/slices/filters'
import { RefreshIcon } from '../../../../_metronic/assets/icons';

type activitiesIconsType = {
  [key: string]: string
}

const activitiesFilter = [
  'Flight',
  'Car',
  'Bus', 
  'Train', 
  'Metro', 
  'Motorcycle', 
  'Auto Rickshaw', 
  'My Office', 
  'Office', 
  'My Home', 
  'Residential', 
  'Airport',
  'Commercial',
  'Hotel', 
  'Hospital',
  'Institution', 
  'Mall',
  'Market',
  'Restaurant',
  'School',
  'Station',
];

const activitiesIcons: activitiesIconsType = {
  'Flight': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/aeroplane.png', 
  'Car': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/sedan.png',
  'Train': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/train.png', 
  'Bus': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/bus.png',
  'Metro': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/train.png', 
  'Motorcycle': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/motorbike.png', 
  'Auto Rickshaw': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/auto-rickshaw.png', 
  'My Office': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/office.png', 
  'Office': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/office.png', 
  'My Home': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/home.png', 
  'Residential': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/residence.png', 
  'Airport': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/airport.png', 
  'Commercial': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/commercial.png',
  'Hotel': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/hotel.png', 
  'Hospital': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/hospital.png',
  'Institution': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/institution.png', 
  'Mall': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/mall.png',
  'Market': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/market.png',
  'Restaurant': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/restaurant.png',
  'School': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/school.png',
  'Station': 'https://mynzo.s3.ap-south-1.amazonaws.com/icons/station.png',
}

export const LeaderboardActivitiesFilter = () => {
  const dispatch = useDispatch();
  const {filterLeaderboardActivities} = useSelector((state: RootState) => state.filters);

  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  const handleFilterLeaderboardActivities = (value: string) => {
    if (!filterLeaderboardActivities.includes(value)) {
      dispatch(addLeaderboardActivitiesFilter(value));
    } else {
      dispatch(removeLeaderboardActivitiesFilter(value));
    }
  }

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      dispatch(resetLeaderboardActivitiesFilter());
    } else {
      setSelectAll(true);
      dispatch(selectAllLeaderboardActivitiesFilter(activitiesFilter))
    }
  }

  const handleResetAll = () => {
    dispatch(resetLeaderboardActivitiesFilter());
  }

  return (
    <>
      <hr style={{color: '#787878'}} />
      <div className='d-flex justify-content-between'>
        <h4 className="mb-4 mt-2 fs-4" style={{color: '#787878'}}>ACTIVITY</h4>
        <button
          type='button'
          className='remove-btn-styles py-0 px-4 my-2 mx-5'
          onClick={handleResetAll}
          style={{
            color: filterLeaderboardActivities.length>0 ? '#FFFFFF' : '#787878',
            backgroundColor: filterLeaderboardActivities.length>0 ? '#3993C2' : '#FFFFFF',
            borderRadius: '20px',
            border: filterLeaderboardActivities.length>0 ? 'none' : '1px solid #d8d8d8',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center'
          }}
          >
          <RefreshIcon 
            height={12} 
            width={12} 
            color={filterLeaderboardActivities.length>0 ? '#FFFFFF' : '#787878'} 
            style={{marginRight: '5px'}}
            /> 
          RESET
        </button>
        {/* <label className='d-flex align-items-center'>
          <p className='fs-6 m-0' style={{color: '#787878', fontWeight: '600'}}>SELECT ALL</p>
          <input 
            type="checkbox" 
            checked={selectAll} 
            onChange={toggleSelectAll}
            className='mx-4'
            style={{height: '16px', width: '16px', accentColor: '#62909A'}}
          />
        </label> */}
      </div>
      <div className='d-flex flex-wrap mx-0 px-0 mb-6'>
      {
        activitiesFilter.map((value:string) => (
        <div key={value} className='justify-content-end' style={{marginRight: '10px'}}>
          <button
            type='button'
            // disabled={!loading}
            className={`${filterLeaderboardActivities.includes(value) ? 'custom-primary-delete-button-v2' : 'custom-outline-button-v2'} d-flex align-items-center m-0 mb-3 py-1 px-5 fs-8`}
            style={{
              borderRadius: '20px',
              fontWeight: 700,
              backgroundColor: filterLeaderboardActivities.includes(value) ? '#E7E0F0' : '#fff',
              border: '1px solid #604A80',
              color: '#604A80',
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              handleFilterLeaderboardActivities(value);
            }}
          >
            <div style={{marginRight: '10px'}}>
              <img alt={value} src={activitiesIcons[value]} height={16} width={16} />
            </div>
            {value.toUpperCase()}
          </button>
        </div>
        ))
      }
      </div>
    </>
  )
}
