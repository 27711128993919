import { useState } from "react";
import { useDispatch } from "react-redux";
import { getStateList, getCityList } from "./api";
import { updateSurveyInputDataOfficeElectricityConsumptionDetails } from "../../../store/slices/profile";

export const useSetOfficeDetailsWithStatesAndCityLists = () => {
  const dispatch = useDispatch()
  const [updatedOfficeDetails, setUpdatedOfficeDetails] = useState([])
  const setOfficeElectricityConsumptionDetails = async(officeElectricityConsumptionDetails: any) => {
    if (updatedOfficeDetails === officeElectricityConsumptionDetails) {
      return;
    }
    let updatedOfficeElectricityConsumptionDetails = [];
    updatedOfficeElectricityConsumptionDetails = officeElectricityConsumptionDetails?.map(async (office: any) => {
      let stateList = []
      let cityList = []
      let currentState = null
      if (office?.country?.id) {
        const stateResponse = await getStateList(office.country.id)
        stateList = stateResponse?.data
        // SET STATE LIST
        office.stateList = stateList || []
      }
      if (office?.state?.name) {
        currentState = stateList.find((state: any) => state.mg_name === office.state.name) || null
        // SET STATE
        office.state = { id: currentState?.mg_id || null, name: currentState?.mg_name || '' }
      }
      if (currentState?.mg_id) {
        const cityResponse = await getCityList(currentState.mg_id)
        cityList = cityResponse?.data
        // SET CITY LIST
        office.cityList = cityList || []
        if (office?.city?.name) {
          const currentCity = cityList.find((city: any) => city.mg_name === office.city.name) || null
          // SET CITY
          office.city = { id: currentCity?.mg_id || null, name: currentCity?.mg_name || '' }
        }
      } else {
        office.cityList = []
        office.city = { id: null, name: '' }
      }
      return office;
    });
    setUpdatedOfficeDetails(updatedOfficeElectricityConsumptionDetails)
    dispatch(updateSurveyInputDataOfficeElectricityConsumptionDetails(updatedOfficeElectricityConsumptionDetails));
  }
  return [setOfficeElectricityConsumptionDetails];
}
