import { styles } from "./styles";

type InputTextProps = {
  value: string,
  onChange: (value: string) => void,
  placeholder?: string,
  className?: string,
  label?: string,
  containerStyle?: React.CSSProperties,
}

type InputNumberProps = {
  value: number | undefined,
  onChange: (value: number | undefined) => void,
  placeholder?: string,
  className?: string,
  label?: string,
  unit?: string,
  containerStyle?: React.CSSProperties,
}

export const InputText = (props: InputTextProps) => {
  const {value, onChange, label, placeholder, className, containerStyle} = props;

  return (
    <div style={containerStyle}>
      {label && <p style={styles.label}>{label}</p>}
      <div style={styles.greyBorder}>
        <div style={styles.inputText}>
          <input
            placeholder={placeholder}
            style={styles.input}
            value={value}
            onChange={(v) => onChange(v.currentTarget.value)}
            />
        </div>
      </div>
    </div>
  )
}

export const InputNumber = (props: InputNumberProps) => {
  const {value, onChange, label, placeholder, className, unit, containerStyle} = props;

  return (
    <div style={containerStyle}>
      {label && <p style={styles.label}>{label}</p>}
      <div style={styles.greyBorder}>
        <div style={styles.inputNumber}>
          <input
            type='number'
            placeholder={placeholder}
            style={styles.input}
            value={value ?? ''} 
            onChange={(v) => {
              const val = v.currentTarget.value;
              if (val === '') {
                onChange(undefined);
              } else if (Number(val) >= 0) {
                onChange(Number(val));
              }
            }}
            />
          {unit && <p style={styles.inputNumberUnit}>{unit}</p>}
        </div>
      </div>
    </div>
  )
}