/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { RoutesPath } from '../../routing/RoutesPath';
import './styles.css';
import Timeline from './components/Timeline'
import ForestTypesCarousel from './components/carousels/forest-types-carousel'
import ForestMapBoundary from './components/maps/forest-map-boundary'
import ForestBoundaryCarousel from './components/carousels/forest-boundary-carousel'
import { ForestDetailIndividual } from '../our-forest/types'
import ForestCardBig from './components/cards/forest-card-big'
import HorizontalHeaderCard from './components/cards/horizontal-header-card'
import { HeaderInfoCard } from './components/cards/header-info-card'

type Props = {
}

const ForestDetailsPage: React.FC<Props> = () => {

  const navigate = useNavigate();

  const {forest, forestData}: any = useLocation().state;

  return (
    <div className='blank-page-view py-8 px-12'>

      <div className='back-header-btn'>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.FOREST.ROOT)}}
        >
          <div className='back-left-arrow-btn' /> 
        </button>
      </div>

      <div className='mt-6 d-flex justify-content-between'>

        <div className='col-8'>
          <div className='' style={{marginRight: 15}}>
            <ForestCardBig 
              forestName={`${forest?.forestName}`}
              companyName="Mynzo Carbon"
              forestNumber={`${forest?.forestNumber}`}
              location='Andhra Pradesh, India'
            />
          </div>

          <div className='mt-4 d-flex flex-row gap-1 position-relative' style={{marginRight: 15}}>
            <div className='flex-grow-1' style={{flex: 1}}>
              {/* <HeaderInfoCard label='TOTAL FOREST AREA' value={(forest?.totalForestArea || 0).toFixed(2)} unit='Acres' /> */}
              <HeaderInfoCard label='TOTAL FOREST AREA' value={(forest?.forestName === "Forest-1" ? '10.85' : '10.85')} unit='Acres' />
            </div>
            <div className='flex-grow-1' style={{flex: 1}} >
              {/* <HeaderInfoCard label='TOTAL FOREST PLANTED' value={(forest?.totalForestPlanted || 0).toFixed(2)} unit='Acres' /> */}
              <HeaderInfoCard label='TOTAL FOREST PLANTED' value={(forest?.forestName === "Forest-1" ? '10.85' : '10.85')} unit='Acres' />
            </div>
            <div className='flex-grow-1' style={{flex: 1}} >
              {/* <HeaderInfoCard label='TOTAL TRESS PLANTED' value={String(Math.round(forest?.totalTreesPlanted || 0))} unit='Trees' /> */}
              <HeaderInfoCard label='TOTAL TRESS PLANTED' value={(forest?.forestName === "Forest-1" ? '2633' : '2600')} unit='Trees' />
            </div>
            <div className='flex-grow-1' style={{flex: 1}}>
              {/* <HeaderInfoCard label='TOTAL RECAPTURED' value={String(Math.round(forest?.totalRecaptured || 0))} unit='kgCO2' type='white' /> */}
              <HeaderInfoCard label='TOTAL RECAPTURED' value='13945' unit='kgCO2' type='white' />
            </div>
          </div>

          <div className='mt-4' style={{marginRight: 15}}>
            {/* <HorizontalHeaderCard title="RECAPTURED PER SAPLING" value={forestData?.totalRecapturedPerSapling} unit="kgCO2" isMarginBottom={false} disableUnit /> */}
            <HorizontalHeaderCard title="RECAPTURED PER SAPLING" value='5.3' unit="kgCO2" isMarginBottom={false} disableUnit />
          </div>

          <div className='mt-4' style={{marginRight: 15}}>
            <HorizontalHeaderCard title="TOTAL NUMBER OF FARMERS" value={forestData?.totalFarmers} isMarginBottom={false} disableUnit />
          </div>

          <h2 className='m-0 mt-12 font-weight-normal'>SPECIES OF TREES</h2>
          <ForestTypesCarousel data={forestData?.speciesPlantedPerAcre} />

          <div className="mt-12">
            <h2 className='m-0 mb-4'>SATELLITE IMAGES</h2>
            <div className='d-flex flex-row gap-4' style={{height: '280px'}}>
              <div className='bordered-grey flex-1 position-relative' style={{height: '100%', width: '50%'}}>
                <ForestBoundaryCarousel />
              </div>
              <div className='bordered-grey flex-1 position-relative' style={{width: '50%', marginRight: '15px'}}>
                {
                  forest && (
                    <ForestMapBoundary forest={forest} />
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div className='card custom-border col-4 px-5 pt-5 position-relative forest-logs-wrapper position-sticky' style={{top: 70}}>
          <h3 className='px-15 mt-4 pb-4'>RECENT UPDATES</h3> 
          {/* <Timeline updates={forest?.recentUpdates} /> */}
          <Timeline updates={[{date: '2023-06-01', area: 10.85}]} />
        </div>
      </div>
      
    </div>
  )
}

export default ForestDetailsPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */