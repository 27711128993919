import React, { useState, useRef, useEffect } from 'react';
import './styles.css';

interface Position {
  x: number;
  y: number;
}

interface ForestScrollProps {
  src?: string;
  alt?: string;
}

export default function ForestScroll({
  src = 'https://images.unsplash.com/photo-1682687220742-aba13b6e50ba?w=1000&h=1000',
  alt = 'forest',
}: ForestScrollProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const [startPos, setStartPos] = useState<Position>({ x: 0, y: 0 });
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const img = new Image();
    img.src = src;

    img.onload = () => {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      // Calculate aspect ratios
      const imageRatio = img.naturalWidth / img.naturalHeight;
      const containerRatio = containerWidth / containerHeight;

      // Determine dimensions to fit container while maintaining aspect ratio
      let width;
      let height;
      if (imageRatio > containerRatio) {
        // Image is wider than container ratio - fit to width
        width = containerWidth;
        height = containerWidth / imageRatio;
      } else {
        // Image is taller than container ratio - fit to height
        height = containerHeight;
        width = containerHeight * imageRatio;
      }

      setImageDimensions({
        width,
        height
      });
    };
  }, [src]);

  /* Commented drag functionality
  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !containerRef.current) return;

    const newX = e.clientX - startPos.x;
    const newY = e.clientY - startPos.y;

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;

    // Boundary checks
    const maxX = 0;
    const minX = containerWidth - imageDimensions.width;
    const maxY = 0;
    const minY = containerHeight - imageDimensions.height;

    setPosition({
      x: Math.min(maxX, Math.max(minX, newX)),
      y: Math.min(maxY, Math.max(minY, newY)),
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startPos.x, startPos.y]);

  const handleZoomIn = () => {
    setImageDimensions(prev => ({
      width: prev.width * 1.2,
      height: prev.height * 1.2
    }));
  };

  const handleZoomOut = () => {
    if (!containerRef.current) return;
    
    setImageDimensions(prev => ({
      width: Math.max(prev.width * 0.8, containerRef.current?.clientWidth || 0),
      height: Math.max(prev.height * 0.8, containerRef.current?.clientHeight || 0)
    }));
  };
  */

  return (
    <div
      ref={containerRef}
      // role="button" // Commented out as no longer needed
      // tabIndex={0}  // Commented out as no longer needed
      // onMouseDown={handleMouseDown} // Commented out drag functionality
      style={{ 
        height: '100%', 
        width: '90%', 
        overflow: 'hidden', 
        position: 'relative', 
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: `100%`,
          borderRadius: '10px',
          height: `100%`,
          objectFit: 'contain'
          // Commented out drag-related styles
          // transform: `translate(${position.x}px, ${position.y}px)`,
          // cursor: isDragging ? 'grabbing' : 'grab',
          // userSelect: 'none',
          // pointerEvents: 'none',
          // position: 'absolute',
        }}
      />
      {/* Commented zoom controls
      <div style={{ 
        position: 'absolute', 
        bottom: '20px', 
        right: '20px', 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '10px',
        zIndex: 1000
      }}>
        <button
          type='button'
          onClick={handleZoomIn}
          style={{
            width: '40px',
            height: '40px',
            border: 'none',
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.2)'
          }}
        >
          +
        </button>
        <button
          type='button'
          onClick={handleZoomOut}
          style={{
            width: '40px',
            height: '40px',
            border: 'none',
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.2)'
          }}
        >
          -
        </button>
      </div>
      */}
    </div>
  );
}
