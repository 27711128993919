/* eslint-disable prefer-spread */
import React, { DependencyList, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop, PixelCrop, type Crop } from 'react-image-crop'

import { ChallengesTitleImage } from '../../../../../_metronic/assets/images';
import { DoneCircularIcon } from '../../../../../_metronic/assets/icons';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { RootState } from '../../../../store/store';
import { 
  CATEGORY_PRE_DEFINED_CHALLENGE,
  ChallengeActionType,
  ChallengeActionTypeConfig,
  DEFAULT_CHALLENGE_IMG_CONFIG, 
  ENUM_PRE_DEFINED_CHALLENGE_TYPE, 
  PRE_DEFINED_CHALLENGES_CONFIG, 
  PreDefinedChallengeType 
} from '../types';
import { setChallengesFormField } from '../../../../store/slices/forms/challengesForm';
import { usePostOrgUploadImageMutation } from '../../store/api';

import "../styles.css";
import 'react-image-crop/dist/ReactCrop.css'

type PropsType = {
  handleFormNavigation: (navigateTo: "part_one" | "part_two") => void
}

const PartOneCreateChallengePage: React.FC<PropsType> = (props) => {

  const {handleFormNavigation} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { challengesForm } = useSelector((state: RootState) => state);

  /* ----------------------------------- STATES ----------------------------------- */

  const [isCropImageActive, setIsCropImageActive] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop | undefined>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })
  const [imgSrc, setImgSrc] = useState('')
  const imgRef = useRef<HTMLImageElement>(null)
  const [aspect, setAspect] = useState<number | undefined>(8 / 5)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [isImageSizeError, setIsImageSizeError] = useState<boolean>(false)
  // VALIDATION STATES
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true);
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);
  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [isValidActionText, setIsValidActionText] = useState<boolean>(true);
  const [isValidDescription, setIsValidDescription] = useState<boolean>(true);

  /* ---------------------------------- VALIDATION ---------------------------------- */

  const checkIsValid = () => {
    const validStartDate = !!challengesForm.startDate;
    const validEndDate = !!challengesForm.endDate;
    const validName = challengesForm.name.length > 5;
    const validActionText = challengesForm.actionText.length > 10;
    const validDescription = challengesForm.description.length > 10;
    // const validImage = challengesForm.image.length > 0;

    if (!validStartDate) setIsValidStartDate(false);
    if (!validEndDate) setIsValidEndDate(false);
    if (!validName) setIsValidName(false);
    if (!validActionText) setIsValidActionText(false);
    if (!validDescription) setIsValidDescription(false);

    if (challengesForm.isPreDefined && validStartDate && validEndDate) return true;

    return (validStartDate && validEndDate && validName && validActionText && validDescription);
  }

  useEffect(() => {
    if (challengesForm.name.length > 5) setIsValidName(true);
    if (challengesForm.description.length > 10) setIsValidDescription(true);
    if (challengesForm.startDate) setIsValidStartDate(true);
    if (challengesForm.endDate) setIsValidEndDate(true);
  }, [challengesForm.name, challengesForm.description, challengesForm.startDate, challengesForm.endDate]);

  /* ---------------------------------------- APIS -------------------------------------- */

  const [
    postOrgUploadImage, {
      isSuccess: isSuccessPostOrgUploadImage,
      isLoading: isLoadingPostOrgUploadImage,
      isError: isErrorPostOrgUploadImage
    }
  ] = usePostOrgUploadImageMutation();


  /* ------------------------------------ HANDLERS ------------------------------------ */

  const handleIsSelectedChallengeTypeRadioSelect = (arg: boolean) => {
    dispatch(setChallengesFormField({field: "isPreDefined", data: arg}))
  }

  const handleSelectedDefaultChallengeRadioSelect = (arg: PreDefinedChallengeType) => {
    dispatch(setChallengesFormField({field: "preDefinedChallengeId", data: arg}))
  }

  const handleSaveAsDraft = () => {
    navigate(RoutesPath.CHALLENGES.ROOT);
  }

  const handleContinuePartOne = async () => {
    if (checkIsValid()) {
      if (imgSrc.length) {
        const imgRes = await fetch(imgSrc);
        const imgBlob = await imgRes.blob();
        const imgFile = new File([imgBlob], 'challengeImg.png', { type: 'image/png' });
        
        const image = new FormData();
        image.append('image', imgFile);
        image.append('directory', 'challenges');
  
        postOrgUploadImage(image)
        .then((res:any) => dispatch(setChallengesFormField({field: 'image', data: res?.data?.data?.result})));
      }

      handleFormNavigation("part_two");
    }
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspectR: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspectR,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function getActionTextPlaceholder() {
    let placeholder = 'Example - Upload a picture of your artwork.';
    switch(challengesForm.actionType) {
      case ChallengeActionType.UPLOAD_SINGLE_IMAGE: 
        placeholder = 'Example - Upload a picture of your artwork.';
        break;
      case ChallengeActionType.USER_MARK:
        placeholder = 'Example - Mark as complete.';
        break;
      case ChallengeActionType.USER_MARK_DAILY:
        placeholder = 'Example - Mark as complete till duration of the challenge.';
        break;
      case ChallengeActionType.POST_ON_FEED:
        placeholder = 'Example - Post on feed.';
        break;
      default: 
        placeholder = 'Example - Upload a picture of your artwork.';
        break;
    }
    return placeholder;
  }


  return (
    <div className='mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='d-flex align-items-center justify-content-center'>
        <ChallengesTitleImage height={80} width={100} className='mt-2' />
        <h2 className='m-0 px-6' style={{fontWeight: '800'}}>CREATE A CHALLENGE</h2>
      </div>
      <div className='my-6 d-flex align-items-center justify-content-center'>
        <div className='position-relative'>
          <DoneCircularIcon height={24} width={24} color="#0491BD" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-60px'
            }}
            >
              Challenges description
          </p>
        </div>
        <div className='line-bar' style={{marginLeft: '4px', backgroundColor: "#0491BD"}} />
        <div className='line-bar' style={{marginRight: '4px', backgroundColor: "#D9D9D9"}} />
        <button 
          type="button"
          className="position-relative remove-btn-styles"
          // onClick={() => handleFormNavigation("part_two")}
          style={{cursor: 'default'}}
          >
          <DoneCircularIcon height={24} width={24} color="#D9D9D9" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-60px'
            }}
            >
            Participant&apos;s information
          </p>
        </button>
      </div>

      {/* --------------------------------------- BODY ------------------------------------------ */} 

      <form className='mt-10 mx-0 col'>

        <div className='row pt-12'>
          <div className='col-6 m-0 d-flex' style={{flexDirection: 'column'}}>
            <h6 className='fs-5 m-0 mb-2'>SELECT START DATE</h6>
            <DatePicker
              className='challenges-datepicker-styles'
              selected={challengesForm.startDate ? new Date(challengesForm.startDate) : undefined} 
              onChange={(date: any) => dispatch(setChallengesFormField({field: "startDate", data: date}))}
              minDate={new Date()}
              maxDate={challengesForm.endDate ? new Date(challengesForm.endDate) : undefined}
              placeholderText='DD/MM/YYYY'
              dateFormat='dd/MM/YYYY'
              showMonthDropdown
              showYearDropdown
              // disabled={challengeData?.status && challengeData?.status === 'live'}
              />
            {
              !isValidStartDate &&
              <p style={{color: 'red', margin: '0px'}}>*Start date is required</p>
            }
          </div>

          <div className='col-6 m-0 d-flex' style={{flexDirection: 'column'}}>
            <h6 className='fs-5 m-0 mb-2'>SELECT END DATE</h6>
            <DatePicker
              className='challenges-datepicker-styles'
              selected={challengesForm.endDate ? new Date(challengesForm.endDate) : undefined} 
              onChange={(date: any) => dispatch(setChallengesFormField({field: "endDate", data: date}))}
              minDate={challengesForm.startDate ? new Date(challengesForm.startDate) : new Date()}
              placeholderText='DD/MM/YYYY'
              dateFormat='dd/MM/YYYY'
              showMonthDropdown
              showYearDropdown
              // disabled={challengeData?.status && challengeData?.status === 'live'}
              />
            {
              !isValidEndDate &&
              <p style={{color: 'red', margin: '0px'}}>*End date is required</p>
            }
          </div>
        </div>

        <div className='row pt-12'>
          <div className='col-6'>
            <label className='d-flex align-items-center' htmlFor='pre-defined' style={{cursor: 'pointer'}}>
              <h6 className='fs-5 m-0'>PRE-DEFINED CHALLENGES</h6>
              <input 
                type="radio"
                name="challenge_type"
                className="mx-4 radio-button-styles"
                id="pre-defined"
                value="PRE-DEFINED CHALLENGES"
                checked={challengesForm.isPreDefined}
                onChange={() => handleIsSelectedChallengeTypeRadioSelect(true)}
                />
            </label>
          </div>

          <div className='col-6'>
            <label className='d-flex align-items-center' htmlFor='custom' style={{cursor: 'pointer'}}>
              <h6 className='fs-5 m-0'>CUSTOM CHALLENGES</h6>
              <input 
                type="radio"
                name="challenge_type"
                className="mx-4 radio-button-styles"
                id="custom"
                value="CUSTOM CHALLENGES"
                checked={!challengesForm.isPreDefined}
                onChange={() => handleIsSelectedChallengeTypeRadioSelect(false)}
                />
            </label>
          </div>
        </div>

        {
          challengesForm.isPreDefined ? (
            <>
            <div className='row m-0'>
              {/* CATEGORY DROPDOWN */}
              <div className='col-6 p-0 mt-8'>
                <select
                  className='form-select'
                  onChange={(event: any) => {
                    dispatch(setChallengesFormField({field: "preDefinedChallengeCategoryId", data: Number(event.target.value)}));
                  }}
                  style={{border: '1px solid #bababa', color: "#202020"}}
                  >
                  <option value={0}>All Categories</option>
                  <optgroup label='Categories'>
                    {
                      Object.values(CATEGORY_PRE_DEFINED_CHALLENGE).map((item: any) => (
                        <option value={item.ID} key={item.ID}>
                          {item.CATEGORY}
                        </option>
                      ))
                    }
                  </optgroup>
                </select>
              </div>
              <div className='col-6' />
            </div>
            <div className='bordered-grey mt-8' style={{borderRadius: '15px'}}>
              <table className=' bordered-grey challenges-type-table-styles'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.values(PRE_DEFINED_CHALLENGES_CONFIG).filter((challenge: any) => {
                      if (challengesForm.preDefinedChallengeCategoryId === 0) return true;
                      return challengesForm.preDefinedChallengeCategoryId === challenge.CATEGORY_ID
                      }).map((preChallenge: any, index: number) => (
                        <tr className='position-relative' key={`${preChallenge.ID}`}>
                          <td className='d-flex align-items-center' style={{width: '100%', borderWidth: `${index===0?'0px': '1px'} 0px 0px 0px`}}>
                            <img 
                              alt={preChallenge.NAME} 
                              src={preChallenge.IMAGE}
                              style={{height: '40px', width: '65px', borderRadius: '20px', marginRight: '15px'}}
                              />
                            <h6
                              className='m-0'
                              style={{fontWeight: '800'}}
                              >
                              {preChallenge.NAME}
                            </h6>
                          </td>
                          <td style={{width: '72%'}}>
                            <label className='d-flex align-items-center position-relative' htmlFor={preChallenge.ID} style={{cursor: 'pointer', fontSize: '14px'}}>
                              <div className='d-flex' style={{flexDirection: 'column', width: '95%'}}>
                                <ul className='m-0 mb-2 create-challenges-bullet-points' style={{paddingLeft: '15px'}}>
                                  <li className='m-0 mb-2'>{preChallenge.NAME}</li>
                                  <li className='m-0 mb-2'>{preChallenge.ACTION_TEXT}</li>
                                  <li className='m-0 mb-2'>{preChallenge.COINS_PER_USER} Mynzo coins as rewards</li>
                                </ul>
                                <span>
                                  {preChallenge.DESCRIPTION}
                                </span>
                              </div>
                              <input 
                                type="radio"
                                name="default_challenge_type"
                                className="mx-4 radio-button-styles"
                                id={preChallenge.ID}
                                style={{height: '16px', width: '20px',}}
                                defaultChecked={challengesForm.preDefinedChallengeId === preChallenge.ID}
                                onChange={() => handleSelectedDefaultChallengeRadioSelect(preChallenge.ID)}
                                />
                            </label>
                          </td>
                        </tr>
                      )
                    )
                  }
                </tbody>
              </table>
            </div>
            </>
          ) : (
            <div className='row mt-10'>
              <div className='col-12'>
                <h6 className='fs-5'>CHALLENGE NAME</h6>
                <div className='wrap-custom-default-input-styles'>
                  <input
                    placeholder='Enter challenge name'
                    className='custom-default-input-styles'
                    value={challengesForm.name}
                    onChange={(v) => {
                      dispatch(setChallengesFormField({field: "name", data: v.currentTarget.value}));
                    }}
                    />
                </div>
                {
                  !isValidName &&
                  <p style={{color: 'red', margin: '0px'}}>*Challenge name is required (*min 6 chars)</p>
                }
              </div>

              {/* ACTION TYPE DROPDOWN */}
              <div className='row mt-8' style={{paddingRight: '0px'}}>
                <div className='col-6 m-0'>
                <h6 className='fs-5'>SELECT USER ACTION</h6>
                  <select
                    className='form-select'
                    onChange={(event: any) => {
                      dispatch(setChallengesFormField({field: "actionType", data: event.target.value}));
                    }}
                    style={{border: '1px solid #bababa', color: "#202020"}}
                    >
                    <optgroup label="Upload Media">
                      <option value={ChallengeActionTypeConfig.UPLOAD_SINGLE_IMAGE.type} key={ChallengeActionTypeConfig.UPLOAD_SINGLE_IMAGE.type}>
                        {ChallengeActionTypeConfig.UPLOAD_SINGLE_IMAGE.label}
                      </option>
                      <option value={ChallengeActionTypeConfig.POST_ON_FEED.type} key={ChallengeActionTypeConfig.POST_ON_FEED.type}>
                        {ChallengeActionTypeConfig.POST_ON_FEED.label}
                      </option>
                    </optgroup>
                    <optgroup label="Mark Complete">
                      <option value={ChallengeActionTypeConfig.USER_MARK.type} key={ChallengeActionTypeConfig.USER_MARK.type}>
                        {ChallengeActionTypeConfig.USER_MARK.label}
                      </option>
                      <option value={ChallengeActionTypeConfig.USER_MARK_DAILY.type} key={ChallengeActionTypeConfig.USER_MARK_DAILY.type}>
                        {ChallengeActionTypeConfig.USER_MARK_DAILY.label}
                      </option>
                    </optgroup>
                  </select>
                </div>

                <div className='col-6 m-0' style={{paddingRight: '0px'}}>
                <h6 className='fs-5'>DEFINE USER ACTION TEXT</h6>
                <div className='wrap-custom-default-input-styles'>
                  <input
                    placeholder={getActionTextPlaceholder()}
                    className='custom-default-input-styles'
                    value={challengesForm.actionText}
                    onChange={(v) => {
                      dispatch(setChallengesFormField({field: "actionText", data: v.currentTarget.value}));
                    }}
                    />
                </div>
                {
                  !isValidActionText &&
                  <p style={{color: 'red', margin: '0px'}}>*Action text is required (*min 10 chars)</p>
                }
                </div>
              </div>

              <div className='col-12'>
                <h6 className='mt-8 fs-5'>CHALLENGE DESCRIPTION</h6>
                <div className='wrap-custom-default-input-styles'>
                  <textarea
                    placeholder='Enter challenge description'
                    className='custom-default-input-styles'
                    value={challengesForm.description}
                    onChange={(v) => {
                      dispatch(setChallengesFormField({field: "description", data: v.currentTarget.value}));
                    }}
                    />
                </div>
                {
                  !isValidDescription &&
                  <p style={{color: 'red', margin: '0px'}}>*Challenge description is required (*min 10 chars)</p>
                }
              </div>

              <div className='col-12'>
                <h6 className='mt-8 fs-5'>CHALLENGE DISPLAY PICTURE</h6>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center mb-10' style={{flexDirection: 'column'}}>
                    {
                      Object.values(DEFAULT_CHALLENGE_IMG_CONFIG).map((imgUrl: string) => (
                        <button 
                          key={imgUrl}
                          type="button"
                          className="remove-btn-styles"
                          onClick={() => {
                            setIsImageSizeError(false);
                            dispatch(setChallengesFormField({field: 'image', data: imgUrl}));
                            setIsCropImageActive(false);
                            setImgSrc('');
                          }}
                          style={{
                            borderWidth: challengesForm.image === imgUrl ? 2 : 0,
                            borderStyle: 'solid',
                            borderColor: '#0491BD',
                            borderRadius: '22px',
                            padding: '2px',
                            marginBottom: '5px'
                          }}
                          >
                          <img
                            alt={imgUrl}
                            src={imgUrl}
                            style={{
                              alignSelf: 'center', 
                              height: '50px',
                              width: '80px',
                              objectFit: 'cover',
                              borderRadius: '20px',
                            }}
                            />
                        </button>
                      ))
                    }
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50px',
                        width: '80px',
                        borderRadius: '20px',
                        backgroundColor: '#eaeaea'
                      }}
                      >
                      <p className='m-0' style={{zIndex: 0, fontSize: '28px', cursor: 'wait',}}>+</p>
                      <input
                        type="file"
                        className='m-0 p-4'
                        style={{opacity: 0, zIndex: 100, position: 'absolute', width: '80px', height: '50px'}}
                        accept="image/*" 
                        onChange={(e) => {
                          if (e.target.files?.length && e.target.files[0].size > 1000000) {
                            setIsImageSizeError(true);
                          } else {
                            setIsImageSizeError(false);
                            onSelectFile(e)
                            setIsCropImageActive(true)
                            dispatch(setChallengesFormField({field: 'image', data: ""}));
                          }
                        }}
                        />
                    </div>
                  </div>
                  {
                    (isCropImageActive && imgSrc)? (
                      <ReactCrop 
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                        minWidth={400}
                        minHeight={400}
                        style={{borderRadius: '20px'}}
                        // circularCrop
                        >
                        <img 
                          ref={imgRef}
                          alt={imgSrc} 
                          src={imgSrc}
                          style={{
                            alignSelf: 'center', 
                            height: '400px',
                            borderRadius: '20px'
                          }}
                          onLoad={onImageLoad}
                          />
                      </ReactCrop>
                    ) : (
                      <img
                        alt={challengesForm.image}
                        src={challengesForm.image}
                        style={{
                          alignSelf: 'center', 
                          height: '400px',
                          borderRadius: '20px'
                        }}
                        />
                     )
                  }
                  <div/>
                </div>
              {
                isImageSizeError &&
                <p style={{color: 'red', margin: '0px', marginTop: '10px', textAlign: 'center'}}>*The image exceeds the <span className='bold'>1MB</span> size limit, please choose a different image.</p>
              }
              </div>
            </div>
          )
        }

        <div className='my-8 d-flex align-items-center justify-content-evenly'>
          {/* <button
            type='button'
            // disabled={!loading}
            className='custom-group-category-filter-button m-0 mt-8 px-14'
            style={{
              borderRadius: '8px',
              fontWeight: '700'
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSaveAsDraft}
            >
            SAVE AS DRAFT
          </button> */}

          <button
            type='button'
            // disabled={!loading}
            className='custom-group-category-filter-button m-0 mt-8 px-20'
            style={{
              borderRadius: '8px',
              fontWeight: '700'
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleContinuePartOne}
            >
            CONTINUE
          </button>
        </div>

      </form>

    </div>
  )
}

export default PartOneCreateChallengePage;
/* eslint-disable prefer-spread */