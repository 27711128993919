/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react'
import { commaSeparatedFormat } from '../../../utils/helpers'
import ReactTablePurple from '../../../../_metronic/partials/components/tables/ReactTablePurple'
import { ReductionInitiativesStatus } from '../types'

// Render components for table cells
const RenderText = ({ value }: { value: string }) => (
  <div className="px-1 d-flex justify-content-center"><p className='m-0'>{value}</p></div>
)

const RenderNumber = ({ value }: { value: number }) => (
  <div className="px-1  d-flex justify-content-center"><p className='m-0'>{commaSeparatedFormat(value)}</p></div>
)

const RenderStatus = ({ value }: { value: string }) => {
  switch (value) {
    case "in_progress":
      return <div className="px-4 d-flex justify-content-center"><p className='m-0' style={{color: '#c9922c'}}>In Progress</p></div>
    case "completed":
      return <div className="px-4 d-flex justify-content-center"><p className='m-0' style={{color: '#267522'}}>Completed</p></div>
    case "not_started":
      return <div className="px-4 d-flex justify-content-center"><p className='m-0' style={{color: 'red'}}>Not Started</p></div>
    default:
      return <div className="px-4 d-flex justify-content-center"><p className='m-0' style={{color: 'red'}}>Not Started</p></div>
  }
}

const ReductionInitiativesTable = ({ listData = [] }: { listData: any[] }) => {
  const headers = useMemo(
    () => [
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>INITIATIVE</p></div>,
        accessor: 'initiative',
        Cell: ({ cell }: { cell: any }) => <RenderText value={cell.row.original.initiative} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>TARGET REDUCTION (tCO2e)</p></div>,
        accessor: 'targetReduction',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={cell.row.original.targetReduction} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>TARGET DATE</p></div>,
        accessor: 'targetDate',
        Cell: ({ cell }: { cell: any }) => <RenderText value={cell.row.original.targetDate} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>LEAD (Role/Dept)</p></div>,
        accessor: 'lead',
        Cell: ({ cell }: { cell: any }) => <RenderText value={cell.row.original.lead} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>STATUS</p></div>,
        accessor: 'status',
        Cell: ({ cell }: { cell: any }) => <RenderStatus value={cell.row.original.status} />
      },
    ],
    []
  )

  return (
    <div className='p-0 m-0'>
      <ReactTablePurple
        headerData={headers}
        tableData={listData}
        className='mt-6 custom-border'
        currentPageIndex={0}
        disableSort
        showPagination={false}
        pageSize={listData.length}
        message="No results found"
      />
    </div>
  )
}

export default ReductionInitiativesTable
