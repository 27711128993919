
import { useDispatch, useSelector } from 'react-redux'
import { CSSProperties, useEffect, useState } from 'react'

import { RootState } from '../../../../store/store'
import { 
  UploadDataScopeOneSubcategoriesFilterType, 
  UploadDataScopeTwoSubcategoriesFilterType, 
  updateUploadDataScopeOneSubcategoriesFilterType, 
  updateUploadDataScopeTwoSubcategoriesFilterType,
  updateUploadScopesReportFilterType,
  UploadDataScopeOneSubcategoriesEnum,
  UploadDataScopeTwoSubcategoriesEnum
} from '../../../../store/slices/filters'

import '../styles.css'
import { IconCarPurple, IconCarTwoPurple, IconVehiclesPurple } from '../../../../../_metronic/assets/icons'

type statusType = "not_uploaded" | "in_progress" | "completed"

type CategoryFilterScopeOneType = {
  label: string,
  value: UploadDataScopeOneSubcategoriesFilterType,
  icon: JSX.Element,
  status: statusType
}

type CategoryFilterScopeTwoType = {
  label: string,
  value: UploadDataScopeTwoSubcategoriesFilterType,
  icon: JSX.Element,
  status: statusType
}

const getStyleStatus = (status: statusType) => {
  const style: CSSProperties = {
    padding: '2px 14px',
    borderRadius: '25px',
    fontWeight: '700',
    backgroundColor: '#EBE5F5',
    color: '#181C32',
  }
  if (status === "not_uploaded") {
    style.backgroundColor = '#ffb8b8'
    style.color = '#640C0C'
  } else if (status === "in_progress") {
    style.backgroundColor = '#F6E9CE'
    style.color = '#49350D'
  } else {
    style.backgroundColor = '#dbf7c8'
    style.color = '#0B4A19'
  }
  return style
}

const getStylesTip = () => {
  const styles: CSSProperties = {
    width: '0px',
    height: '0px',
    position: 'absolute',
    zIndex: 1,
    transform: 'rotate(180deg)',
    bottom: -30,
    left: '46%',
    borderStyle: 'solid',
    borderWidth: '0 20px 25px 20px',
    borderColor: 'transparent transparent #EBE5F5 transparent',
    // boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
  }
  return styles
}

const getLabelStatus = (status: statusType) => {
  if (status === "not_uploaded") return "Not Uploaded"
  else if (status === "in_progress") return "In Progress"
  else return "Completed"
}

export const FilterSubcategories = () => {  
  const dispatch = useDispatch();
  const {filterUploadDataScopeOneSubcategories, filterUploadDataScopeTwoSubcategories, filterUploadScopesReport} = useSelector((state: RootState) => state.filters);
  const {uploadDataStatus} = useSelector((state: RootState) => state);

  const [categoriesFilterScopeOne, setCategoriesFilterScopeOne] = useState<CategoryFilterScopeOneType[]>([]);
  const [categoriesFilterScopeTwo, setCategoriesFilterScopeTwo] = useState<CategoryFilterScopeTwoType[]>([]);

  useEffect(() => {
    setCategoriesFilterScopeOne([
      {
        label: "Mobile Consumption",
        value: UploadDataScopeOneSubcategoriesEnum.MOBILE_CONSUMPTION,
        icon: <IconCarPurple height={26} width={26} />,
        status: uploadDataStatus.sOneMobileConsumption
      },
      {
        label: "Fugitive Emissions",
        value: UploadDataScopeOneSubcategoriesEnum.FUGITIVE_EMISSIONS,
        icon: <IconCarTwoPurple height={26} width={26} />,
        status: uploadDataStatus.sOneFugitiveVehicles
      },
      {
        label: "Stationary Combustion",
        value: UploadDataScopeOneSubcategoriesEnum.STATIONARY_COMBUSTION,
        icon: <IconVehiclesPurple height={26} width={26} />,
        status: uploadDataStatus.sOneStationaryCombustion
      },
      {
        label: "Other",
        value: UploadDataScopeOneSubcategoriesEnum.OTHER,
        icon: <IconVehiclesPurple height={26} width={26} />,
        status: uploadDataStatus.sOneOther
      }
    ]);
    setCategoriesFilterScopeTwo([
      {
        label: "Location Based Method",
        value: UploadDataScopeTwoSubcategoriesEnum.LOCATION_BASED_METHOD,
        icon: <IconCarPurple height={26} width={26} />,
        status: uploadDataStatus.sTwoLocationBasedMethod
      },
      {
        label: "Market Based Method",
        value: UploadDataScopeTwoSubcategoriesEnum.MARKET_BASED_METHOD,
        icon: <IconCarTwoPurple height={26} width={26} />,
        status: uploadDataStatus.sTwoMarketBasedMethod
      },
      {
        label: "Other",
        value: UploadDataScopeTwoSubcategoriesEnum.OTHER,
        icon: <IconVehiclesPurple height={26} width={26} />,
        status: uploadDataStatus.sTwoOther
      }
    ]);
  }, [uploadDataStatus])

  const handleScopeOneSubcategory = (value: UploadDataScopeOneSubcategoriesFilterType) => {
    if (value === filterUploadDataScopeOneSubcategories) dispatch(updateUploadDataScopeOneSubcategoriesFilterType(UploadDataScopeOneSubcategoriesEnum.MOBILE_CONSUMPTION))
    else dispatch(updateUploadDataScopeOneSubcategoriesFilterType(value));
  }

  const handleScopeTwoSubcategory = (value: UploadDataScopeTwoSubcategoriesFilterType) => {
    if (value === filterUploadDataScopeTwoSubcategories) dispatch(updateUploadDataScopeTwoSubcategoriesFilterType(UploadDataScopeTwoSubcategoriesEnum.LOCATION_BASED_METHOD))
    else dispatch(updateUploadDataScopeTwoSubcategoriesFilterType(value));
  }

  return (
    <div className='d-flex mx-0 px-0 mb-6 gap-2 align-items-center justify-content-between position-relative' style={{zIndex: 1}}>
    {
      filterUploadScopesReport === 'scope1' ?
      categoriesFilterScopeOne.map((value: CategoryFilterScopeOneType, index: number) => (
      <div key={value.value} className='position-relative' style={{height: '100px', flex: 1}}>
        <button
          type='button'
          // disabled={!loading}
          className={`${filterUploadDataScopeOneSubcategories === value.value ? 'upload-data-subcategory-filter-button' : 'upload-data-subcategory-filter-outline-button'}`}
          style={{
            backgroundColor: filterUploadDataScopeOneSubcategories === value.value ? '#EBE5F5' : '#f5f5f5',
            zIndex: 0
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleScopeOneSubcategory(value.value);
          }}
        >
          <div className='d-flex justify-content-between flex-column' style={{height: '100%'}}>
            <div className='m-0 d-flex align-items-center flex-start gap-2'>
              {value.icon}
              <p className='m-0 fs-6' style={{fontWeight: 700}}>{value.label}</p>
            </div>
            <div className='d-flex justify-content-end'>
              <p className='m-0 fs-7' style={getStyleStatus(value.status)}>
                {getLabelStatus(value.status)}
              </p>
            </div>
          </div>  
        </button>
        {
          value.value === filterUploadDataScopeOneSubcategories &&
          <div style={getStylesTip()} />
        }
      </div>
      ))
      :
      categoriesFilterScopeTwo.map((value: CategoryFilterScopeTwoType, index: number) => (
      <div key={value.value} className='position-relative' style={{height: '100px', flex: 1}}>
        <button
          type='button'
          className={`${filterUploadDataScopeTwoSubcategories === value.value ? 'upload-data-subcategory-filter-button' : 'upload-data-subcategory-filter-outline-button'}`}
          style={{
            backgroundColor: filterUploadDataScopeTwoSubcategories === value.value ? '#EBE5F5' : '#f5f5f5',
            zIndex: 0
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleScopeTwoSubcategory(value.value);
          }}
        >
          <div className='d-flex justify-content-between flex-column' style={{height: '100%'}}>
            <div className='m-0 d-flex align-items-center flex-start gap-2'>
              {value.icon}
              <p className='m-0 fs-6' style={{fontWeight: 700}}>{value.label}</p>
            </div>
            <div className='d-flex justify-content-end' >
              <p className='m-0 fs-7' style={getStyleStatus(value.status)}>
                {getLabelStatus(value.status)}
              </p>
            </div>
          </div>
        </button>
        {
          value.value === filterUploadDataScopeTwoSubcategories &&
          <div style={getStylesTip()} />
        }
      </div>
      ))
    }
    </div>
  )
}
