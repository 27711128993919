/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { commaSeparatedFormat } from '../../../utils/helpers';
import { RootState } from '../../../store/store';

interface TimelineItemProps {
date: string;
data?: [{
    species_name:string
    tree_planted:number
    images?:[string]
}];
acrePlanted: number;
index: number;
isLast?: boolean;
}

type TimelineProps = {
}

// const TimelineItem: React.FC<TimelineItemProps> = ({ date, data,index }) => {

//     const renderTimelineElement:React.ReactElement[] = []
//     data.forEach((itm)=>(
//         renderTimelineElement.push(
//             <p>{itm.tree_planted} trees of {itm.species_name} planted.</p>
//         )
//     ))

//     const dispDate = new Date(date).toLocaleDateString('en-GB');
//     return (
//         <div className="vertical-timeline-item vertical-timeline-element mt-10">
//             <div>
//             <span className="vertical-timeline-element-icon bounce-in">
//                 <i className="badge badge-dot badge-dot-xl badge-dark"> </i>
//             </span>
//             <div className="vertical-timeline-element-content bounce-in">
//                 {renderTimelineElement}
//             <span className="vertical-timeline-element-date">{dispDate}</span>
//             </div>
//             </div>
//         </div>
//     )
// };

const TimelineItem: React.FC<TimelineItemProps> = ({ date, acrePlanted, index, data, isLast = false }) => {

    // const renderTimelineElement:React.ReactElement[] = []
    // data.forEach((itm)=>(
    //     renderTimelineElement.push(
    //         <p className='m-0'>{commaSeparatedFormat(itm.tree_planted)} trees of {itm.species_name} planted.</p>
    //     )
    // ))

    const dispDate = moment(date).format('DD/MM/YYYY');
    return (
        <li className='mx-5 pb-4 position-relative'>
            {
                !isLast && (
                    <div className='timeline-line-grey-2' />
                )
            }
			<h6>{Number(acrePlanted).toFixed(2)} acres planted</h6>
            <h6 className='sub-title mb-0'>{dispDate}</h6>

            {
                (index === 0) && (
                    <a 
                      className='mt-6' 
                      href='https://drive.google.com/file/d/1GUP1CKQZF8W4Fo_K4NkEdhNDaRIrv68i/view?usp=sharing'
                      style={{color: '#0000FF', textDecoration: 'underline'}}
                    >
                    Plantation updates
                    </a>
                )
            }
            {/* {renderTimelineElement} */}
        </li>
    )   
};

const Timeline: React.FC<any>= ({updates}) => {

    const {profile} = useSelector((state:RootState) => state.profile)

    const [forestUpdatesData, setForestUpdatesData] = useState<any[]>([]);

    // const {data:forestUpdatesData} = {data: [
    //     {
    //         "visit_date": "2023-10-30",
    //         "acre_planted": 5.80,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1,
    //                 "images": ['asdf']
    //             }
    //         ]
    //     }, 
    //     {
    //         "visit_date": "2023-11-15",
    //         "acre_planted": 5.00,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1, 
    //                 "images": ['asdf']
    //             }
    //         ]
    //     },
    //     {
    //         "visit_date": "2023-10-30",
    //         "acre_planted": 5.80,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1,
    //                 "images": ['asdf']
    //             }
    //         ]
    //     }, 
    //     {
    //         "visit_date": "2023-11-15",
    //         "acre_planted": 5.00,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1, 
    //                 "images": ['asdf']
    //             }
    //         ]
    //     },
    //     {
    //         "visit_date": "2023-10-30",
    //         "acre_planted": 5.80,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1,
    //                 "images": ['asdf']
    //             }
    //         ]
    //     }, 
    //     {
    //         "visit_date": "2023-11-15",
    //         "acre_planted": 5.00,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1, 
    //                 "images": ['asdf']
    //             }
    //         ]
    //     },
    //     {
    //         "visit_date": "2023-10-30",
    //         "acre_planted": 5.80,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1,
    //                 "images": ['asdf']
    //             }
    //         ]
    //     }, 
    //     {
    //         "visit_date": "2023-11-15",
    //         "acre_planted": 5.00,
    //         "data": [
    //             {
    //                 "species_name": "Placeholder",
    //                 "tree_planted": 1, 
    //                 "images": ['asdf']
    //             }
    //         ]
    //     }
    // ]};

    // let forestUpdatesData: any[] = [];

    useEffect(() => {
        if (updates) {
            const updatedData = updates.map((update: any) => ({
                visit_date: update.date,
                acre_planted: update.area,
                data: [{
                    species_name: "Placeholder",
                    tree_planted: 1,
                    images: ['asdf']
                }]
            }));
            setForestUpdatesData(updatedData);
        }
    }, [updates]);

    if (!forestUpdatesData){
        return null;
    }

    return (
//     <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column mt-4 mb-2">
   
//     </div>
        <ul className="timeline forest-timeline-logs">
        {/* {(forestUpdatesData && !dummyData) && ( */}
        {(forestUpdatesData) && (
            forestUpdatesData?.map((item,index)=>(
                <TimelineItem 
                    key={`${index}-${item.visit_date}`}
                    date={item.visit_date} 
                    acrePlanted={item.acre_planted} 
                    index={index}
                    isLast={index === forestUpdatesData.length - 1}
                />
            ))
        )} 
        {/* {
            dummyData && (
                dummyData?.map((item,index)=>(
                    <TimelineItem 
                        key={`${index}-${item.visit_date}`}
                        date={item.visit_date} 
                        acrePlanted={item.acre_planted} 
                        index={index}
                    />
                ))
            )
        } */}
        </ul>
);
};

export default Timeline;
