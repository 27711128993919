/* eslint-disable global-require */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MarkerIcon } from '../../../../../_metronic/assets/icons';

type Props = {
  forestName: string;
  companyName: string;
  forestNumber: string;
  location: string;
}

const FOREST_IMG_SOURCE: {[key: string]: string} = {
  "1": require('../../../../../_metronic/assets/images/png-forest-1.png'),
  "2": require('../../../../../_metronic/assets/images/png-forest-2.png'),
}


const ForestCardBig: React.FC<Props> = (props) => {
  const { forestName, companyName, forestNumber, location } = props;
  const navigate = useNavigate();

  return (
    <div className='forest-card mb-2 remove-button-styles' style={{textAlign: 'left', backgroundColor: '#FFFFFF',}}>
      <div className='d-flex flex-column gap-2'>
        <h1 className='m-0'>{forestName}</h1>
        <h4 className='m-0 mb-2' style={{ color: '#808080', fontSize: 14 }}>{companyName}</h4>
        <div className='d-flex flex-row gap-2 align-items-center'>
          <MarkerIcon color="black" height={24} style={{marginLeft: 0}} />
          <h6 className='m-0' style={{ fontSize: 14, width: '95%' }}>{location}</h6>
        </div>
      </div>
      <img src={FOREST_IMG_SOURCE[`${forestNumber}`]} alt={forestName} height={90} width={90} />
    </div>
  )
}

export default ForestCardBig;