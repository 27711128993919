export const RenderStatusPill = (props: {status: "completed" | "in_progress" | "not_uploaded"}) => {
  const {status} = props;

  let label;
  let color;

  switch (status) {
    case "completed":
      label = "Completed";
      color = "#368800";
      break;
    case "in_progress":
      label = "In Progress";
      color = "#FF9812";
      break;
    case "not_uploaded":
      label = "Not Uploaded";
      color = "#952020";
      break;
    default:
      label = "Not Uploaded";
      color = "#952020";
      break;
  }

  return (
    <div className="px-4 py-2" style={{borderRadius: 15, backgroundColor: 'white', border: `1px solid ${color}`}}>
      <h4 className='m-0 p-0' style={{color, fontSize: 14, fontWeight: 700, textAlign: 'center'}}>{label}</h4>
    </div>
  )
}