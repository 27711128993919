/* eslint-disable dot-notation */
import {createSlice} from '@reduxjs/toolkit'

type StatusType = "not_uploaded" | "in_progress" | "completed"

type UPLOAD_DATA_FIELDS = 
  "sOneMobileConsumption" |
  "sOneFugitiveVehicles" |
  "sOneStationaryCombustion" |
  "sOneOther" |
  "sTwoMarketBasedMethod" |
  "sTwoLocationBasedMethod" |
  "sTwoOther" | 
  "sOneMobileCompanyOwnedVehicles" |
  "sOneMobileThirdPartyTransportation" |
  "sOneMobileLeasedVehicles" |
  "sOneFugitiveRefrigerantLeakage" |
  "sOneFugitiveFireExtinguishers" |
  "sOneStationaryHeatingSystems" |
  "sOneStationaryGenerators" |
  "sOneStationaryBoilersAndFurnaces" |
  "sOneOtherOther" |
  "sTwoMarketBasedMethodPowerPurchasedAgreement" |
  "sTwoLocationBasedMethodNaturalGas" |
  "sTwoLocationBasedMethodElectricity" |
  "sTwoOtherOther"

export type UploadDataStatusType = {
  sOneMobileConsumption: StatusType
  sOneFugitiveVehicles: StatusType
  sOneStationaryCombustion: StatusType
  sOneOther: StatusType
  sTwoMarketBasedMethod: StatusType
  sTwoLocationBasedMethod: StatusType
  sTwoOther: StatusType
  sOneMobileCompanyOwnedVehicles: StatusType
  sOneMobileThirdPartyTransportation: StatusType
  sOneMobileLeasedVehicles: StatusType
  sOneFugitiveRefrigerantLeakage: StatusType
  sOneFugitiveFireExtinguishers: StatusType
  sOneStationaryHeatingSystems: StatusType
  sOneStationaryGenerators: StatusType
  sOneStationaryBoilersAndFurnaces: StatusType
  sOneOtherOther: StatusType
  sTwoMarketBasedMethodPowerPurchasedAgreement: StatusType
  sTwoLocationBasedMethodNaturalGas: StatusType
  sTwoLocationBasedMethodElectricity: StatusType
  sTwoOtherOther: StatusType
}

const initialState: UploadDataStatusType = {
  sOneMobileConsumption: "not_uploaded",
  sOneFugitiveVehicles: "not_uploaded",
  sOneStationaryCombustion: "not_uploaded",
  sOneOther: "not_uploaded",
  sTwoMarketBasedMethod: "not_uploaded",
  sTwoLocationBasedMethod: "not_uploaded",
  sTwoOther: "not_uploaded",
  sOneMobileCompanyOwnedVehicles: "not_uploaded",
  sOneMobileThirdPartyTransportation: "not_uploaded",
  sOneMobileLeasedVehicles: "not_uploaded",
  sOneFugitiveRefrigerantLeakage: "not_uploaded",
  sOneFugitiveFireExtinguishers: "not_uploaded",
  sOneStationaryHeatingSystems: "not_uploaded",
  sOneStationaryGenerators: "not_uploaded",
  sOneStationaryBoilersAndFurnaces: "not_uploaded",
  sOneOtherOther: "not_uploaded",
  sTwoMarketBasedMethodPowerPurchasedAgreement: "not_uploaded",
  sTwoLocationBasedMethodNaturalGas: "not_uploaded",
  sTwoLocationBasedMethodElectricity: "not_uploaded",
  sTwoOtherOther: "not_uploaded",
}

export type SetUploadDataStatusActionType = {
  payload: {
    field: UPLOAD_DATA_FIELDS,
    data: StatusType,
  },
  type: string
}

export const uploadDataStatusSlice = createSlice({
  name: 'uploadDataStatus',
  initialState,
  reducers: {
    setUploadDataStatus: (state: any, action: SetUploadDataStatusActionType) => {
      state[action.payload.field] = action.payload.data;
      if (action.payload.data === "in_progress") {
        if (["sOneMobileCompanyOwnedVehicles", "sOneMobileThirdPartyTransportation", "sOneMobileLeasedVehicles"].includes(action.payload.field)) {
          state["sOneMobileConsumption"] = "in_progress"
        }
        if (["sOneFugitiveRefrigerantLeakage", "sOneFugitiveFireExtinguishers"].includes(action.payload.field)) {
          state["sOneFugitiveVehicles"] = "in_progress"
        }
        if (["sOneStationaryHeatingSystems", "sOneStationaryGenerators", "sOneStationaryBoilersAndFurnaces"].includes(action.payload.field)) {
          state["sOneStationaryCombustion"] = "in_progress"
        }
        if (["sTwoLocationBasedMethodNaturalGas", "sTwoLocationBasedMethodElectricity"].includes(action.payload.field)) {
          state["sTwoLocationBasedMethod"] = "in_progress"
        }
        if (["sTwoMarketBasedMethodPowerPurchasedAgreement"].includes(action.payload.field)) {
          state["sTwoMarketBasedMethod"] = "in_progress"
        }
        if (["sTwoOtherOther"].includes(action.payload.field)) {
          state["sTwoOther"] = "in_progress"
        }
        if (["sOneOtherOther"].includes(action.payload.field)) {
          state["sOneOther"] = "in_progress"
        }
      }
    },
  },
})

export const {setUploadDataStatus} = uploadDataStatusSlice.actions

export default uploadDataStatusSlice.reducer