const API_ENDPOINTS = {
  ADMINS: '/admins',
  ME: '/me',
  EMPLOYEE_CREATE: '/upload-single-employee',
  ADMIN_UPDATE: '/update',
  UPLOAD_EMPLOYEES: '/upload-employees',
  UPLOAD_SCOPE_DATA: '/upload-s1-s2-data'
}

export default API_ENDPOINTS
