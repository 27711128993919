import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import ForestScroll from './forest-scroll';

/* eslint-disable global-require */
const images = [
  {
    year: 2011,
    src: require('../../../assets/satellite-images/2011.jpg'),
    alt: 'Year 2011'
  },
  {
    year: 2012,
    src: require('../../../assets/satellite-images/2012.jpg'),
    alt: 'Year 2012'
  },
  {
    year: 2013,
    src: require('../../../assets/satellite-images/2013.jpg'),
    alt: 'Year 2013'
  },
  {
    year: 2014,
    src: require('../../../assets/satellite-images/2014.jpg'),
    alt: 'Year 2014'
  },
  {
    year: 2015,
    src: require('../../../assets/satellite-images/2015.jpg'),
    alt: 'Year 2015'
  },
  {
    year: 2016,
    src: require('../../../assets/satellite-images/2016.jpg'),
    alt: 'Year 2016'
  },
  {
    year: 2017,
    src: require('../../../assets/satellite-images/2017.jpg'),
    alt: 'Year 2017'
  },
  {
    year: 2018,
    src: require('../../../assets/satellite-images/2018.jpg'),
    alt: 'Year 2018'
  },
  {
    year: 2019,
    src: require('../../../assets/satellite-images/2019.jpg'),
    alt: 'Year 2019'
  },
  {
    year: 2020,
    src: require('../../../assets/satellite-images/2020.jpg'),
    alt: 'Year 2020'
  },
  {
    year: 2021,
    src: require('../../../assets/satellite-images/2021.jpg'),
    alt: 'Year 2021'
  },
  {
    year: 2022,
    src: require('../../../assets/satellite-images/2022.jpg'),
    alt: 'Year 2022'
  },
  {
    year: 2023,
    src: require('../../../assets/satellite-images/2023.jpg'),
    alt: 'Year 2023'
  },
  {
    year: 2024,
    src: require('../../../assets/satellite-images/2024.jpg'),
    alt: 'Year 2024'
  },
];

const years = images.map(image => image.year);
const maxYear = Math.max(...years);
const minYear = Math.min(...years);

const TimelineSlider: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  // const [isFading, setIsFading] = useState<boolean>(false);

  const [year, setYear] = useState(minYear); // Initial year for the range

  const handleChange = (event: any) => {
    const newIndex = years.findIndex(_year => _year === parseInt(event.target.value, 10));    
    setCurrentImageIndex(newIndex);
    setYear(event.target.value);
  };

  // const sliderPercentage = (currentImageIndex / (images.length - 1)) * 100;

  const sliderPercentage = (year - minYear) / (maxYear - minYear) * 100;

  return (
    <Container className="timeline-slider">
      <ForestScroll src={images[currentImageIndex].src} alt={`Year ${images[currentImageIndex].year}`} />


      <div className="timeline-container-range">
      <span className="timeline-label">{minYear}</span>

      <div className="timeline-wrapper">
        <input
          type="range"
          min={minYear}
          step={1}
          max={maxYear}
          value={year}
          onChange={handleChange}
          style={{background: `linear-gradient(to right, #9268CC 0%, #463578 ${sliderPercentage}%, #ccc ${sliderPercentage}%, #ccc 100%)`}}
          className="timeline-range"
        />
        <div className="range-tooltip" style={{ left: `${sliderPercentage + 2*(50-sliderPercentage)/50}%` }}>
          {year}
        </div>
      </div>

      <span className="timeline-label">{maxYear}</span>
    </div>
    </Container>
  );
};

export default TimelineSlider;
