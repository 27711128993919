import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EmissionDonutContributionChart from './charts/emission-donut-contribution-chart'
import { HeaderInfoCard } from './components/header-info-card'
import { TopCategoryEmission } from './charts/top-categories-emission-chart'
import { locationEmissionData } from './docs-data'
import { NetZeroStatusCard } from './charts/net-zero-status-circle'
import HighlightsCard from './components/highlights-card'
import LocationEmissionTable from './components/location-emission-table'
import { RoutesPath } from '../../routing/RoutesPath'
import { useGetProfileDetailsQuery } from '../../routing/store/api'
import { updateProfile } from '../../store/slices/profile'
import { RootState } from '../../store/store'
import { IconLockWhite } from '../../../_metronic/assets/icons'
import LockWrapper, { LockWrapperPartial } from '../../../_metronic/partials/components/lock-wrapper/LockWrapper'

const MOBILE_WIDTH = 992
const emptyTopCategoriesData = [{
  title: '--',
  emission: 0,
  percentage_contribution: 0
}, {
  title: '-- ',
  emission: 0,
  percentage_contribution: 0
}, {
  title: '--  ',
  emission: 0,
  percentage_contribution: 0
}]

const DashboardPage = () => {
  // TODO: Implement DashboardPage
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {surveyBasedData, profileBasedData, lockedScreens} = useSelector((state: RootState) => state.profile)

  const [topCategoriesEmissionData, setTopCategoriesEmissionData] = useState<any[]>([])
  const [totalScopeEmissionsData, setTotalScopeEmissionsData] = useState<any[]>([])

  const {data: profileDetails} = useGetProfileDetailsQuery()

  useEffect(() => {
    if (profileDetails && !surveyBasedData) {
      dispatch(updateProfile(profileDetails))
    }
  }, [profileDetails, surveyBasedData])

  const getTotalScopeEmissionsDonutData = () => [
    {name: 'Scope 1', count: Math.round((surveyBasedData?.totalEmissions?.scope1 || 0)/1000)},
    {name: 'Scope 2', count: Math.round((surveyBasedData?.totalEmissions?.scope2 || 0)/1000)},
    {name: 'Scope 3', count: Math.round((surveyBasedData?.totalEmissions?.scope3 || 0)/1000)}
  ]

  const getTopCategoriesEmissionData = () => {
    const totalEmissions = surveyBasedData?.topCategories?.reduce((acc: number, item: any) => acc + item.emission, 0);
    const result = surveyBasedData?.topCategories?.map((item: any) => ({
      title: item.category,
      emission: item.emission,
      percentage_contribution: ((item.emission || 0)/totalEmissions) * 100
    }))
    return result
  }

  useEffect(() => {
    if (surveyBasedData) {
      setTotalScopeEmissionsData(getTotalScopeEmissionsDonutData() || [])
      setTopCategoriesEmissionData(getTopCategoriesEmissionData() || [])
    }
  }, [surveyBasedData])

  getTopCategoriesEmissionData()

  return (
    <LockWrapper isLocked={lockedScreens.dashboardScopePage} description='This page displays information Scope1, Scope 2 and Scope 3 emissions and its details. For more details, contact support. '>
      <div className="blank-page-view pt-10 pb-16 px-6">
        <div className="row p-0 m-0">
          <div className="col-4 m-0 pb-0">
            <h2 className='m-0'>EMISSIONS</h2>
            <div className='mt-4 bordered-grey py-4'>
              <EmissionDonutContributionChart chartHeight='280px' title='' data={totalScopeEmissionsData} />
            </div>
          </div>
        <div className="col-8">
          <h2 className='m-0'>TOP CATEGORIES</h2>
          <div className='mt-4 d-flex'>
            <div className='p-0 my-0' style={{width: '65%'}}>
              {
                topCategoriesEmissionData?.length > 0 ? (
                  <TopCategoryEmission data={topCategoriesEmissionData} />
                ) : (
                  <TopCategoryEmission data={emptyTopCategoriesData} />
                )
              }
            </div>
            <div className='p-0 my-0 d-flex flex-column justify-content-between position-relative' style={{width: '35%', marginLeft: '20px'}}>
              <button 
                type='button'
                className='custom-primary-delete-button m-0 mx-0 position-absolute d-flex align-items-center' 
                style={{backgroundColor: '#036585', padding: '8px 30px 8px 26px', fontWeight: 600, top: '-56px', right: '10px', zIndex: 1000, boxShadow: '1px 1px 1px 1px lightgrey'}}
                // onClick={() => {}}
                >
                <IconLockWhite color='white' height={14} width={14} style={{marginRight: 5}} />
                <span className='m-0'>
                  BRSR Report
                </span>
              </button>
              <div className='p-0 m-0 position-relative' style={{height: '46%'}}>
                <HeaderInfoCard
                  label='EMISSIONS PER EMPLOYEE'
                  value={surveyBasedData?.averageEmissionsPerEmployee !== undefined ? `${((surveyBasedData?.averageEmissionsPerEmployee || 0)/1000).toFixed(2)}` : '--'}
                  unit='tCO2e'
                />
              </div>
              <div className='p-0 m-0 position-relative' style={{height: '46%'}}>
                <HeaderInfoCard 
                  label='EMISSIONS BY REVENUE' 
                  value={surveyBasedData?.emissionByRevenue !== undefined ? `${((surveyBasedData?.emissionByRevenue || 0)/1000).toFixed(2)}` : '--'} 
                  unit='tCO2e' 
                />
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className='row p-0 m-0 mt-14'>
          <div className='d-flex justify-content-between align-items-center'>
            <h2 className='m-0'>NET ZERO STATUS</h2>
            <button
              type='button'
              className='custom-primary-delete-button mb-0 mx-0'
              style={{padding: '8px 20px', fontWeight: 600}}
              onClick={()=> navigate(RoutesPath.DASHBOARD.PROJECTED_EMISSIONS)}
              >
              PROJECTED EMISSIONS
            </button>
          </div>
          <div className='col-3'>
            <div className='mt-2'>
              <NetZeroStatusCard label='NET ZERO STATUS' isAvailable borderClass='bordered-grey' />
            </div>
          </div>
          <div className='col-9'>
            <div className='p-0 m-0 h-100 d-flex align-items-end'>
              <HighlightsCard emissionPerEmployee={surveyBasedData?.averageEmissionsPerEmployee ? (surveyBasedData?.averageEmissionsPerEmployee || 0)/1000 : 0} />
            </div>
          </div>
        </div>

        <div className='row p-0 m-0 mt-14'>
          <div className='col-12'>
            <h2 className='m-0'>LOCATION</h2>
            <div className='mt-4'>
              <LocationEmissionTable listData={surveyBasedData?.totalLocationEmissionData || []} />
            </div>
          </div>
        </div>
      </div>
    </LockWrapper>
  )
}

export default DashboardPage
