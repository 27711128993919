import {createSlice} from '@reduxjs/toolkit'
import {ProfileData} from '../../../_metronic/layout/types'
import {LockedScreensType} from '../types'
import { getLockedScreens } from '../helper'

export type ProfileStoreType = {
  profile: ProfileData | null
  surveyBasedData: any
  profileBasedData: any
  lockedScreens: LockedScreensType
  defaultEngagement: 'PROFILE_BASED' | 'AUTO_TRACKING'
  surveyInputData: any
}

const initialState: ProfileStoreType = {
  profile: null,
  surveyBasedData: null,
  profileBasedData: null,
  lockedScreens: {
    dashboardScopePage: true,
    projectedEmissionsPage: false,
    scopeOnePage: false,
    scopeTwoPage: false,
    scopeThreePage: false,
    forestPage: false,
    uploadDataPage: false,
    surveyQuestionsPage: false,
    forestDocsPage: false,
    dashboardEmissionPage: false,
    detailsPage: false,
    leaderboardPage: false,
  },
  defaultEngagement: 'PROFILE_BASED',
  surveyInputData: {}
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      // logout 
      if (action.payload === null) {
        state.profile = null
        return;
      }
      // update profile
      if (action.payload?.email?.endsWith('@mynzocarbon.com')) {
        state.profile = {...action.payload, isMynzoCarbonOrg: true}
      } else {
        state.profile = {...action.payload, isMynzoCarbonOrg: false}
      }
      state.surveyBasedData = action.payload?.organization?.survey_data
      state.profileBasedData = action.payload?.organization?.profile_based_data
      state.lockedScreens = getLockedScreens(action.payload?.organization?.page_status)
      state.defaultEngagement = action.payload?.organization?.page_status?.["default-engagement"]
      state.surveyInputData = action.payload?.organization?.survey_data?.inputData || {}
    },
    updateSurveyInputDataOfficeElectricityConsumptionDetails: (state, action) => {
      if (action.payload?.length > 0) {
        state.surveyInputData.officeElectricityConsumptionDetails = action.payload
      }
    }
  },
})

export const {
  updateProfile,
  updateSurveyInputDataOfficeElectricityConsumptionDetails
} = profileSlice.actions

export default profileSlice.reducer
