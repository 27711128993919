export const forest1 = {
  totalForestArea: 5,
  totalForestPlanted: 2.5,
  totalTreesPlanted: 600,
  totalRecaptured: 7.5,
  totalRecapturedPerSapling: 0.02,
  totalFarmers: 120,
  speciesPlantedPerAcre: [
    { name: 'Custard Apple', count: 40, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDkJc62IqMAAOMC_PIgECiSV6NSCVqOuKiKw&s" },
    { name: 'Lemon', count: 40, src: "https://images.unsplash.com/photo-1590502593747-42a996133562" },
    { name: 'Teak', count: 50, src: "https://hariyalinursery.in/wp-content/uploads/2020/08/Teak-Plant-Small-Leaves.jpg" },
    { name: 'Indian Rosewood', count: 50, src: "https://nurserylive.com/cdn/shop/products/nurserylive-plants-sheesham-sheesham-tree-indian-rosewood-tree-plant-16969322102924_512x512.jpg?v=1634228847" },
    { name: 'Terminalia arjuna', count: 50, src: "https://upload.wikimedia.org/wikipedia/commons/9/94/Fruit_I_IMG_9577.jpg" },
    { name: 'Silver oak', count: 40, src: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Grevillea_robusta_flowering_in_Gan_Shmuel01.jpg/220px-Grevillea_robusta_flowering_in_Gan_Shmuel01.jpg" },
    // { name: 'Spanish cherry', count: 20, src: "https://m.media-amazon.com/images/I/51McD5xAt5L._SX300_SY300_QL70_FMwebp_.jpg" },
    // { name: 'Guava', count: 40, src: "https://images.unsplash.com/photo-1536511132770-e5058c7e8c46" },
    // { name: 'Ramphal', count: 40, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDkJc62IqMAAOMC_PIgECiSV6NSCVqOuKiKw&s" },
  ],
  recentUpdates: [
    { acres: 0.10, date: '01/15/2023' },
    { acres: 0.15, date: '03/01/2023' },
    { acres: 0.10, date: '05/15/2023' },
    { acres: 0.20, date: '07/10/2023' },
    { acres: 0.10, date: '09/01/2023' },
    { acres: 0.30, date: '02/14/2024' },
    { acres: 0.40, date: '04/10/2024' },
    { acres: 0.30, date: '06/05/2024' },
    { acres: 0.45, date: '09/20/2024' },
    { acres: 0.40, date: '11/30/2024' }
  ]
}

export const forest2 = {
  totalForestArea: 5,
  totalForestPlanted: 3.75,
  totalTreesPlanted: 900,
  totalRecaptured: 11.25,
  totalRecapturedPerSapling: 0.02,
  totalFarmers: 80,
  speciesPlantedPerAcre: [
    { name: 'Custard Apple', count: 35, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDkJc62IqMAAOMC_PIgECiSV6NSCVqOuKiKw&s" },
    { name: 'Lemon', count: 35, src: "https://images.unsplash.com/photo-1590502593747-42a996133562" },
    { name: 'Ramphal', count: 35, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDkJc62IqMAAOMC_PIgECiSV6NSCVqOuKiKw&s" },
    { name: 'Guava', count: 40, src: "https://images.unsplash.com/photo-1536511132770-e5058c7e8c46" },
    { name: 'Terminalia arjuna', count: 40, src: "https://upload.wikimedia.org/wikipedia/commons/9/94/Fruit_I_IMG_9577.jpg" },
    { name: 'Spanish cherry', count: 25, src: "https://m.media-amazon.com/images/I/51McD5xAt5L._SX300_SY300_QL70_FMwebp_.jpg" },
    { name: 'Silver oak', count: 25, src: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Grevillea_robusta_flowering_in_Gan_Shmuel01.jpg/220px-Grevillea_robusta_flowering_in_Gan_Shmuel01.jpg" }
  ],
  recentUpdates: [
    { acres: 0.20, date: '01/15/2023' },
    { acres: 0.25, date: '03/01/2023' },
    { acres: 0.20, date: '05/15/2023' },
    { acres: 0.30, date: '07/10/2023' },
    { acres: 0.25, date: '09/01/2023' },
    { acres: 0.40, date: '03/21/2024' },
    { acres: 0.50, date: '04/10/2024' },
    { acres: 0.50, date: '08/19/2024' },
    { acres: 0.65, date: '09/20/2024' },
    { acres: 0.70, date: '10/30/2024' }
  ]
}

export const forest3 = {
  totalForestArea: 5,
  totalForestPlanted: 4.75,
  totalTreesPlanted: 1140,
  totalRecaptured: 14.25,
  totalRecapturedPerSapling: 0.02,
  totalFarmers: 100,
  speciesPlantedPerAcre: [
    { name: 'Custard Apple', count: 40, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDkJc62IqMAAOMC_PIgECiSV6NSCVqOuKiKw&s" },
    { name: 'Lemon', count: 40, src: "https://images.unsplash.com/photo-1590502593747-42a996133562" },
    { name: 'Ramphal', count: 40, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDkJc62IqMAAOMC_PIgECiSV6NSCVqOuKiKw&s" },
    { name: 'Guava', count: 40, src: "https://images.unsplash.com/photo-1536511132770-e5058c7e8c46" },
    { name: 'Terminalia arjuna', count: 40, src: "https://upload.wikimedia.org/wikipedia/commons/9/94/Fruit_I_IMG_9577.jpg" },
    { name: 'Spanish cherry', count: 20, src: "https://m.media-amazon.com/images/I/51McD5xAt5L._SX300_SY300_QL70_FMwebp_.jpg" },
    { name: 'Silver oak', count: 20, src: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Grevillea_robusta_flowering_in_Gan_Shmuel01.jpg/220px-Grevillea_robusta_flowering_in_Gan_Shmuel01.jpg" }
  ],
  recentUpdates: [
    { acres: 0.25, date: '01/15/2023' },
    { acres: 0.30, date: '03/01/2023' },
    { acres: 0.25, date: '05/15/2023' },
    { acres: 0.40, date: '07/10/2023' },
    { acres: 0.35, date: '09/01/2023' },
    { acres: 0.50, date: '01/14/2024' },
    { acres: 0.60, date: '05/17/2024' },
    { acres: 0.65, date: '08/02/2024' },
    { acres: 0.80, date: '09/20/2024' },
    { acres: 0.85, date: '11/01/2024' }
  ]
}
